import React, { useEffect, useState } from "react";
import MDBox from "components/MDBox";
import { Button, Card, Grid } from "@mui/material";
import BasicLayout from "layouts/authentication/components/BasicLayout";
import Navbar from "layouts/Front-Page/Navbar";
import NewsletterFooter from "layouts/Front-Page/NewsletterFooter";
import { Link, useNavigate } from "react-router-dom";
import { useLocation } from 'react-router-dom';
import { paymentExecute } from "utils/api";
import Loader from "components/Loader";

function SuccessMsj() {

    const navigate = useNavigate()
    const location = useLocation();
    const searchParams = new URLSearchParams(location.search);
    const paymentId = searchParams.get('paymentId');
    const token = searchParams.get('token');
    const PayerID = searchParams.get('PayerID');
    const [status, setStatus] = useState("")
    const [loading, setLoading] = useState(true);


    useEffect(() => {
        setLoading(true);
        paymentExecute({ paymentId: paymentId, token: token, PayerID: PayerID }).then((res) => {
            setLoading(false)
            if (res.status == 200) {
                setStatus("success")
                localStorage.setItem("is_subscription", "true")
                setTimeout(() => {
                    navigate("/")
                }, 3000)
            } else {
                setStatus("error")
            }
        }).catch((error) => {
            console.log(error)
        })
    }, [])

    return (
        <BasicLayout>
            <div className="pt-2">
                <Navbar />
            </div>

            <Grid container justifyContent="center">
                <Grid item xs={12} md={10} lg={10}>
                    <MDBox pt={6} pb={3}>

                        <div className="py-4">
                            {
                                loading ?
                                    <Loader />
                                    :
                                    <Grid container justifyContent="center">
                                        <Grid md={6}>
                                            <Card>
                                                {
                                                    status == "success" ?
                                                        <div className="text-center p-5">
                                                            <svg xmlns="http://www.w3.org/2000/svg" width="50" height="50" fill="currentColor" className="bi bi-check-circle check_icon" viewBox="0 0 16 16">
                                                                <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14m0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16" />
                                                                <path d="m10.97 4.97-.02.022-3.473 4.425-2.093-2.094a.75.75 0 0 0-1.06 1.06L6.97 11.03a.75.75 0 0 0 1.079-.02l3.992-4.99a.75.75 0 0 0-1.071-1.05" />
                                                            </svg>
                                                            <h1 className="thank_you_title">Thank You!</h1>
                                                            <h5 className="payment_done">Payment Done successfully</h5>
                                                            <p className="redirect_msj">You will be redirected to the home page shortly or click here to return to home page.</p>
                                                            <Link to="/dashboard"><button className="home_butn">Home</button></Link>
                                                        </div>
                                                        :
                                                        <div className="text-center p-5">
                                                            <svg xmlns="http://www.w3.org/2000/svg" width="50" height="50" fill="currentColor" className="bi bi-exclamation-circle error_icon" viewBox="0 0 16 16">
                                                                <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14m0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16" />
                                                                <path d="M7.002 11a1 1 0 1 1 2 0 1 1 0 0 1-2 0M7.1 4.995a.905.905 0 1 1 1.8 0l-.35 3.507a.552.552 0 0 1-1.1 0z" />
                                                            </svg>
                                                            <h1 className="failed_msj">Payment Failed!</h1>
                                                            <p className="redirect_msj">Unfortunately, your payment could not be processed. Please try again later or contact support.</p>
                                                            <Link to="/subscription"><button className="try_again_butn">Try Again</button></Link>
                                                        </div>
                                                }
                                            </Card>
                                        </Grid>
                                    </Grid>
                            }
                        </div>

                    </MDBox>
                </Grid>
            </Grid>
            <Grid container justifyContent="center">
                <Grid item md={10}>
                    <NewsletterFooter />
                </Grid>
            </Grid>
        </BasicLayout>
    );
}

export default SuccessMsj;
