import { Card, Grid, Icon } from "@mui/material";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import React, { useEffect, useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import ArrowBackIosNewIcon from "@mui/icons-material/ArrowBackIosNew";
import MDInput from "components/MDInput";
import AirlineLogo from "assets/user-dash/airlineLogo.png";
import HotelImg from "assets/user-dash/hotelimg.png";
import CarImg from "assets/user-dash/carImage.png";
import Loader from "components/Loader";
import { useTheme } from "@mui/material/styles";
import OutlinedInput from "@mui/material/OutlinedInput";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import Pagination from "@mui/material/Pagination";
import { encodeIdWithType } from "utils/encoder";
import { getPermissionByID } from "utils/api";
import { bookingInformation } from "utils/api";

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

const names = ["Rebook Flight", "Rebook Hotel", "Rebook Car"];

const booking_type = ["Live", "Past"];

function getStyles(name, personName, theme) {
  return {
    fontWeight: personName.includes(name)
      ? theme.typography.fontWeightMedium
      : theme.typography.fontWeightRegular,
  };
}

const Bookings = () => {
  const navigate = useNavigate();
  const { id } = useParams();
  const [user, setUser] = useState(null);
  const [loading, setLoading] = useState(false);
  const theme = useTheme();
  const [personName, setPersonName] = useState("Rebook Flight");
  const [bookingType, setBookingType] = useState("Live");
  const [pageData, setPageData] = useState([]);
  const [deletPermision, setDeletPermision] = useState(false);
  const [updatePermision, setUpdatePermision] = useState(false);
  const [userType, setUserType] = useState(null);
  const [show, setShow] = useState(false);

  const itemsPerPage = 5;
  const [page, setPage] = useState(1);

  const pageCount = Math.ceil(pageData?.length / itemsPerPage);

  const displayedItems = pageData?.slice(
    (page - 1) * itemsPerPage,
    page * itemsPerPage
  );

  const bookingDetails = () => {
    setLoading(true);
    bookingInformation("live")
      .then((res) => {
        setLoading(false);
        setUser(res.data);
        setPageData(res.data.flight_data);
      })
      .catch((error) => {
        setLoading(false);
        console.log("booking error", error);
      });
  };

  useEffect(() => {
    bookingDetails();
  }, []);

  const handlePageChange = (event, value) => {
    setPage(value);
  };

  useEffect(() => { });

  const handleBackButtonClick = () => {
    navigate(-1);
  };

  const handleChange = (event) => {
    setPage(1);
    const {
      target: { value },
    } = event;
    setPersonName(value);
    if (value == "Rebook Car") {
      setPageData(user?.car_data);
    } else if (value == "Rebook Hotel") {
      setPageData(user?.hotel_data);
    } else {
      setPageData(user?.flight_data);
    }
  };

  const handleBookingType = (event) => {
    setPage(1);
    // setPersonName("Rebook Flight")
    const {
      target: { value },
    } = event;
    setBookingType(value);
    if (value == "Past") {
      setLoading(true);
      bookingInformation("past")
        .then((res) => {
          setLoading(false);
          setUser(res.data);
          if (personName == "Rebook Car") {
            setPageData(res.data?.car_data);
          } else if (personName == "Rebook Hotel") {
            setPageData(res.data?.hotel_data);
          } else {
            setPageData(res.data?.flight_data);
          }
        })
        .catch((error) => {
          setLoading(false);
          console.log("booking error", error);
        });
    } else if (value == "Live") {
      setLoading(true);
      bookingInformation("live")
        .then((res) => {
          setLoading(false);
          setUser(res.data);
          if (personName == "Rebook Car") {
            setPageData(res.data?.car_data);
          } else if (personName == "Rebook Hotel") {
            setPageData(res.data?.hotel_data);
          } else {
            setPageData(res.data?.flight_data);
          }
        })
        .catch((error) => {
          setLoading(false);
          console.log("booking error", error);
        });
    }
  };

  const redirection = (id, type) => {
    let final_id = encodeIdWithType(id, type);
    navigate(`/booking-details/${final_id}`);
  };

  useEffect(() => {
    getUserPermission();
  }, []);

  const getUserPermission = () => {
    const user = JSON.parse(localStorage.getItem("active-user"));
    if (user) {
      setUserType(user.user_type);
      getPermissionByID(user.id)
        .then((res) => {
          const data = res?.data?.permissions;
          const updateUser = data?.filter((item) => {
            return item.codename == "change_user";
          });
          setUpdatePermision(updateUser?.[0]);
          const deleteUser = data?.filter((item) => {
            return item.codename == "delete_user";
          });
          setDeletPermision(deleteUser?.[0]);
        })
        .catch((error) => {
          console.error("Error fetching permission:", error);
        });
    }
  };

  return (
    <DashboardLayout>
      <DashboardNavbar />
      {loading ? (
        <Loader />
      ) : (
        <MDBox my={2}>
          <div className="user_name_icons">
            <div className="user_detail_section">
              <ArrowBackIosNewIcon onClick={handleBackButtonClick} />
              <MDTypography varient="h1" className="user_fullname">
                Bookings
              </MDTypography>
            </div>
          </div>

          <Card className="mt-4">
            <MDBox py={2} px={2}>
              <div className="filters_sections">
                <MDTypography variant="h6"></MDTypography>
                <div>
                  <div>
                    <MDInput placeholder="Search" />
                    <FormControl sx={{ width: 200, mx: 1 }}>
                      <InputLabel id="demo-multiple-booking_type-label">
                        Booking
                      </InputLabel>
                      <Select
                        labelId="demo-multiple-booking_type-label"
                        id="demo-multiple-booking_type"
                        value={bookingType}
                        onChange={handleBookingType}
                        input={<OutlinedInput label="Booking" />}
                        MenuProps={MenuProps}
                        className="booking_type_select"
                      >
                        {booking_type?.map((name) => (
                          <MenuItem
                            key={name}
                            value={name}
                            style={getStyles(name, bookingType, theme)}
                          >
                            {name}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                    <FormControl sx={{ width: 200, mx: 1 }}>
                      <InputLabel id="demo-multiple-name-label">
                        Category
                      </InputLabel>
                      <Select
                        labelId="demo-multiple-name-label"
                        id="demo-multiple-name"
                        value={personName}
                        onChange={handleChange}
                        input={<OutlinedInput label="Name" />}
                        MenuProps={MenuProps}
                      >
                        {names?.map((name) => (
                          <MenuItem
                            key={name}
                            value={name}
                            style={getStyles(name, personName, theme)}
                          >
                            {name}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  </div>
                </div>
              </div>

              {personName == "Rebook Flight" && (
                <>
                  {displayedItems?.length > 0
                    ? displayedItems?.map((flight) => {
                      return (
                        <Card className="mt-4">
                          {
                            flight?.children?.length > 0 ?
                              <div
                                className="airline_header row"
                                onClick={() => redirection(flight?.id, "flights")}
                              >
                                <div className="airline_data col-md-3">
                                  <img src={AirlineLogo} alt="airline logo" />
                                  <div className="airline_title_section">
                                    <MDTypography
                                      variant="h6"
                                      color="white"
                                      className="air_line_title"
                                    >
                                      {flight?.airline}
                                    </MDTypography>
                                    <div>
                                      {flight?.one_way ? (
                                        <MDTypography
                                          variant="p"
                                          className="ticket_title"
                                        >
                                          One Way
                                        </MDTypography>
                                      ) : (
                                        <MDTypography
                                          variant="p"
                                          className="ticket_title"
                                        >
                                          Round Trip
                                        </MDTypography>
                                      )}
                                    </div>
                                  </div>
                                </div>
                                <div className="col-md-1">
                                  <MDTypography
                                    variant="h6"
                                    className="airline_Text"
                                  >
                                    ${flight?.price}
                                  </MDTypography>
                                </div>

                                <div className="col-md-4">
                                  <MDTypography variant="h6" className="mb-1" style={{ marginLeft: "10px" }}>
                                    Depature
                                  </MDTypography>
                                  <div
                                    className="ticket_detail_heading"
                                  >
                                    <MDTypography variant="h6">
                                      {flight?.departure_city?.split(",")[0]}
                                      -to-
                                      {flight?.destination_city?.split(",")[0]}
                                    </MDTypography>
                                    <div className="ticket_details">

                                      <MDTypography
                                        variant="p"
                                        className="ticket_title"
                                      >
                                        {flight?.flight_number}
                                      </MDTypography>
                                      <span>|</span>
                                      <MDTypography
                                        variant="p"
                                        className="ticket_title"
                                      >
                                        1 Adult
                                      </MDTypography>
                                      <span>|</span>
                                      <MDTypography
                                        variant="p"
                                        className="ticket_title"
                                      >
                                        {flight?.flight_class}
                                      </MDTypography>
                                    </div>
                                    <MDTypography variant="h6" className="ticket_title derature_date_time">
                                      {flight?.departure_date} {flight?.departure_time}
                                    </MDTypography>
                                    <MDTypography variant="h6" className="mt-2 ticket_title derature_date_time">
                                      {flight?.return_date && flight?.Arrives_time
                                        ? `${flight.return_date} ${flight.Arrives_time}`
                                        : flight?.return_date}
                                    </MDTypography>
                                  </div>
                                </div>
                                <div className="col-md-4">
                                  <div>
                                    {flight.children && flight.children.length > 0 && (
                                      <div>
                                        {flight?.children?.map((childFlight, index) => (
                                          <div key={index} className="child_flight_info">
                                            <MDTypography variant="h6" className="mb-1" style={{ marginLeft: "10px" }}>
                                              Return
                                            </MDTypography>
                                            <div
                                              className="ticket_detail_heading"
                                            >
                                              <MDTypography variant="h6">
                                                {childFlight?.departure_city?.split(",")[0]}
                                                -to-
                                                {childFlight?.destination_city?.split(",")[0]}
                                              </MDTypography>
                                              <div className="ticket_details">

                                                <MDTypography
                                                  variant="p"
                                                  className="ticket_title"
                                                >
                                                  {childFlight?.flight_number}
                                                </MDTypography>
                                                <span>|</span>
                                                <MDTypography
                                                  variant="p"
                                                  className="ticket_title"
                                                >
                                                  1 Adult
                                                </MDTypography>
                                                <span>|</span>
                                                <MDTypography
                                                  variant="p"
                                                  className="ticket_title"
                                                >
                                                  {childFlight?.flight_class}
                                                </MDTypography>
                                              </div>
                                              <MDTypography variant="h6" className="ticket_title derature_date_time">
                                                {childFlight?.departure_date} {childFlight?.departure_time}
                                              </MDTypography>
                                              <MDTypography variant="h6" className="mt-2 ticket_title derature_date_time">
                                                {childFlight?.return_date && childFlight?.Arrives_time
                                                  ? `${childFlight.return_date} ${childFlight.Arrives_time}`
                                                  : childFlight?.return_date}
                                              </MDTypography>
                                            </div>
                                          </div>

                                        ))}
                                      </div>
                                    )}
                                  </div>

                                </div>
                              </div>
                              :
                              <div
                                className="airline_header row"
                                onClick={() => redirection(flight?.id, "flights")}
                              >
                                <div className="airline_data col-md-3">
                                  <img src={AirlineLogo} alt="airline logo" />
                                  <div className="airline_title_section">
                                    <MDTypography
                                      variant="h6"
                                      color="white"
                                      className="air_line_title"
                                    >
                                      {flight?.airline}
                                    </MDTypography>
                                    <div>
                                      {flight?.one_way ? (
                                        <MDTypography
                                          variant="p"
                                          className="ticket_title"
                                        >
                                          One Way
                                        </MDTypography>
                                      ) : (
                                        <MDTypography
                                          variant="p"
                                          className="ticket_title"
                                        >
                                          Round Trip
                                        </MDTypography>
                                      )}
                                    </div>
                                  </div>
                                </div>
                                <div className="col-md-1">
                                  <MDTypography
                                    variant="h6"
                                    className="airline_Text"
                                  >
                                    ${flight?.price}
                                  </MDTypography>
                                </div>

                                <div className="col-md-8">
                                  <div
                                    className="ticket_detail_heading"
                                    style={{ marginTop: "20px" }}
                                  >
                                    <MDTypography variant="h6">
                                      {flight?.departure_city?.split(",")[0]}
                                      -to-
                                      {flight?.destination_city?.split(",")[0]}
                                    </MDTypography>
                                    <div className="ticket_details">

                                      <MDTypography
                                        variant="p"
                                        className="ticket_title"
                                      >
                                        {flight?.flight_number}
                                      </MDTypography>
                                      <span>|</span>
                                      <MDTypography
                                        variant="p"
                                        className="ticket_title"
                                      >
                                        1 Adult
                                      </MDTypography>
                                      <span>|</span>
                                      <MDTypography
                                        variant="p"
                                        className="ticket_title"
                                      >
                                        {flight?.flight_class}
                                      </MDTypography>
                                    </div>
                                    <MDTypography variant="h6" className="ticket_title derature_date_time">
                                      {flight?.departure_date} {flight?.departure_time}
                                    </MDTypography>
                                    <MDTypography variant="h6" className="mt-2 ticket_title derature_date_time">
                                      {flight?.return_date && flight?.Arrives_time
                                        ? `${flight.return_date} ${flight.Arrives_time}`
                                        : flight?.return_date}
                                    </MDTypography>
                                  </div>
                                </div>
                              </div>
                          }
                        </Card>
                      );
                    })
                    : ""}
                </>
              )}

              {personName == "Rebook Hotel" && (
                <>
                  {displayedItems?.length > 0
                    ? displayedItems.map((hotel, index) => {
                      return (
                        <Card className="mt-4">
                          <div
                            className="airline_header row"
                            onClick={() => redirection(hotel?.id, "hotels")}
                          >
                            <div className="airline_data col-md-4">
                              <img src={HotelImg} alt="airline logo" />
                              <div className="airline_title_section">
                                <MDTypography
                                  variant="h6"
                                  color="white"
                                  className="air_line_title"
                                >
                                  {hotel?.hotel_name}
                                </MDTypography>
                                <MDTypography
                                  variant="p"
                                  color="white"
                                  className="user_ticket_title"
                                >
                                  Check in: {hotel?.check_in_date}
                                </MDTypography>
                                <MDTypography
                                  variant="p"
                                  color="white"
                                  className="user_ticket_title"
                                >
                                  Check out: {hotel?.check_out_date}
                                </MDTypography>
                              </div>
                            </div>
                            <div className="col-md-4">
                              <MDTypography
                                variant="h6"
                                className="airline_Text"
                              >
                                {hotel?.guest_name}
                              </MDTypography>
                              <MDTypography
                                variant="p"
                                color="white"
                                className="user_ticket_title"
                              >
                                Booking reference: {hotel?.booking_reference}
                              </MDTypography>
                            </div>
                            <div className="col-md-4">
                              <div className="ticket_detail_heading">
                                <MDTypography variant="h6">
                                  {hotel?.rental_price}
                                </MDTypography>
                                <div className="ticket_details">
                                  <MDTypography
                                    variant="p"
                                    className="ticket_title"
                                  >
                                    {hotel?.room_type}
                                  </MDTypography>
                                </div>
                              </div>
                            </div>
                          </div>
                        </Card>
                      );
                    })
                    : ""}
                </>
              )}

              {personName == "Rebook Car" && (
                <>
                  {displayedItems?.length > 0
                    ? displayedItems?.map((car) => {
                      return (
                        <Card className="mt-4">
                          <div
                            className="airline_header row"
                            onClick={() => redirection(car?.id, "cars")}
                          >
                            <div className="airline_data col-md-6">
                              <img src={CarImg} alt="airline logo" />
                              <div className="airline_title_section">
                                <MDTypography
                                  variant="h6"
                                  color="white"
                                  className="air_line_title"
                                >
                                  {car?.car_model}
                                </MDTypography>
                                <MDTypography
                                  variant="p"
                                  color="white"
                                  className="user_ticket_title"
                                >
                                  {`Pick up: ${car?.pickup_date}, ${car?.pickup_location?.length > 20
                                    ? `${car?.pickup_location?.slice(
                                      0,
                                      20
                                    )}...`
                                    : car?.pickup_location
                                    }`}
                                </MDTypography>
                                <MDTypography
                                  variant="p"
                                  color="white"
                                  className="user_ticket_title"
                                >
                                  Drop: {car?.dropoff_date},{" "}
                                  {car?.dropoff_location?.length > 20
                                    ? `${car?.dropoff_location?.slice(
                                      0,
                                      20
                                    )}...`
                                    : car?.dropoff_location}
                                </MDTypography>
                              </div>
                            </div>
                            <div className="col-md-3">
                              <MDTypography
                                variant="h6"
                                className="airline_Text"
                              >
                                {car?.renter_name}
                              </MDTypography>
                              <MDTypography
                                variant="p"
                                color="white"
                                className="user_ticket_title"
                              >
                                Booking reference: {car?.booking_reference}
                              </MDTypography>
                            </div>

                            <div className="col-md-3">
                              <div className="ticket_detail_heading">
                                <MDTypography variant="h6">
                                  {car?.rental_price}
                                </MDTypography>
                                <div className="ticket_details">
                                  <MDTypography
                                    variant="p"
                                    className="ticket_title"
                                  >
                                    {car?.rental_company}
                                  </MDTypography>
                                </div>
                                <div className="ticket_details">
                                  <MDTypography
                                    variant="p"
                                    color="white"
                                    className="user_ticket_title"
                                  >
                                    {car?.rental_class}
                                  </MDTypography>
                                </div>
                              </div>
                            </div>
                          </div>
                        </Card>
                      );
                    })
                    : ""}
                </>
              )}

              <Pagination
                count={pageCount}
                page={page}
                onChange={handlePageChange}
                variant="outlined"
                shape="rounded"
              />
            </MDBox>
          </Card>
        </MDBox>
      )}
    </DashboardLayout>
  );
};

export default Bookings;
