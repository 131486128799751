import React from 'react'
import './Tickets.css';
import MDBox from "components/MDBox";
import airlinrlogo from 'assets/img/airlinrlogo.png'
import MDTypography from 'components/MDTypography';

const FlightBooking = ({ details, totalPrice }) => {

  console.log("detailsdetails",details)

  return (
    <MDBox py={3}>
      <MDTypography variant="p" className="p-title">Price Monitor</MDTypography>
      <div className="container-fluid mb-4 mainpricemonitor">
        <div className="row align-items-center">
          <div className='col-md-3'>
            <p className="Current-Plane">Your Current Plane Ticket   </p>
          </div>
          <div className="col-md-8 dotted-gradient-border">

          </div>
          {details?.length > 0 ? (
            details?.map((flight, index) => (
              <>
                {flight?.children?.length > 0 ?
                  <div key={index} className="currentTicket">
                    <div className='col-md-4 airlinetick'>
                      <div className="">
                        <img
                          src={airlinrlogo}
                          alt=""
                          className="img-fluid"
                        />
                      </div>
                      <div className='ticktname'>
                        <p className="ticktptag1">{flight.airline}</p>
                        <p className='ticktptag2'>{flight?.children?.length > 0 ? "Round Trip" : "Oneway Ticket"}</p>
                        {/* <MDTypography variant="p" className="ticktptag2">
                          Oneway Ticket
                        </MDTypography> */}
                      </div>

                    </div>
                    <div className='col-md-3'>
                      <div>
                        <MDTypography variant="h6" color="white" className="mb-1">
                          Departure
                        </MDTypography>
                        <MDTypography variant="h6" color="white">
                          {flight?.departure_city?.split(",")[0]}-to-
                          {flight?.destination_city?.split(",")[0]}
                        </MDTypography>
                        <MDTypography
                          variant="p"
                          color="white"
                          className="ticket_title"
                        >
                          {`${flight?.flight_number} | 1 Adult | ${flight?.flight_class}`}
                        </MDTypography>
                        <div>
                          <MDTypography
                            variant="p"
                            color="white"
                            className="ticket_title"
                          >
                            {flight?.departure_date} {flight?.departure_time}
                          </MDTypography>
                        </div>
                        <div>
                          <MDTypography
                            variant="p"
                            color="white"
                            className="ticket_title"
                          >
                            {flight?.return_date && flight?.Arrives_time
                              ? `${flight.return_date} ${flight.Arrives_time}`
                              : flight?.return_date}
                          </MDTypography>
                        </div>
                      </div>
                      {/* <div className='listmain'>
                    <p className='listmainptag'>{flight?.departure_city?.split(",")[0]}-to-{flight?.destination_city?.split(",")[0]}</p>
                    <ol className='liststyle'>
                      <li>{flight?.departure_date}</li>
                      <span>|</span>
                      <li>1 Adult</li>
                      <span>|</span>
                      <li>{flight?.flight_class}</li>
                    </ol>
                  </div> */}
                    </div>

                    <div className='col-md-3'>
                      {flight.children && flight.children.length > 0 && (
                        <div>
                          {flight?.children?.map((childFlight, index) => (
                            <div className="child_flight_info">
                              <MDTypography variant="h6" color="white" className="mb-1">
                                Return
                              </MDTypography>
                              <MDTypography
                                variant="h6"
                                color="white"
                              >
                                {childFlight?.departure_city?.split(",")[0]} -to-{" "}
                                {childFlight?.destination_city?.split(",")[0]}
                              </MDTypography>
                              <MDTypography
                                variant="p"
                                color="white"
                                className="ticket_title"
                              >
                                {`${childFlight?.flight_number} | ${flight?.flight_class}`}
                              </MDTypography>

                              <div>
                                <MDTypography
                                  variant="p"
                                  color="white"
                                  className="ticket_title"
                                >
                                  {childFlight?.departure_date} {childFlight?.departure_time}
                                </MDTypography>
                              </div>
                              <div>
                                <MDTypography
                                  variant="p"
                                  color="white"
                                  className="ticket_title"
                                >
                                  {childFlight?.return_date && childFlight?.Arrives_time
                                    ? `${childFlight.return_date} ${childFlight.Arrives_time}`
                                    : childFlight?.return_date}
                                </MDTypography>
                              </div>
                            </div>
                          ))}
                        </div>
                      )}
                    </div>
                    <div className='col-md-2 text-end'>
                      <p className="numberptag text-end">${flight.price}</p>
                      <div className="d-flex justify-content-end align-items-center">
                        <span className="total_price">{totalPrice ? "Now: " : ""}</span>
                        <MDTypography
                          variant="h5"
                          color="white"
                          className="flight_details"
                        >
                          {" "} {totalPrice}
                        </MDTypography>
                      </div>
                    </div>
                  </div>
                  :
                  <div key={index} className="currentTicket">
                    <div className='col-md-4 airlinetick'>
                      <div className="">
                        <img
                          src={airlinrlogo}
                          alt=""
                          className="img-fluid"
                        />
                      </div>
                      <div className='ticktname'>
                        <p className="ticktptag1">{flight.airline}</p>
                        <p className='ticktptag2'>{flight?.children?.length > 0 ? "Round Trip" : "Oneway Ticket"}</p>
                        {/* <MDTypography variant="p" className="ticktptag2">
                          Oneway Ticket
                        </MDTypography> */}
                      </div>

                    </div>
                    <div className='col-md-5'>
                      <div>

                        <MDTypography variant="h6" color="white" className="mb-3">
                          {flight?.departure_city?.split(",")[0]}-to-
                          {flight?.destination_city?.split(",")[0]}
                        </MDTypography>
                        <p
                          style={{color:"white", marginBottom:"0px"}}
                          className="ticket_title"
                        >
                          {`${flight?.flight_number} | 1 Adult | ${flight?.flight_class}`}
                        </p>
                        <div>
                          <p
                            style={{color:"white", marginBottom:"0px"}}
                            className="ticket_title pt-1"
                          >
                            {flight?.departure_date} {flight?.departure_time}
                          </p>
                        </div>
                        <div>
                          <p
                            style={{color:"white", marginBottom:"0px"}}
                            className="ticket_title"
                          >
                            {flight?.return_date && flight?.Arrives_time
                              ? `${flight.return_date} ${flight.Arrives_time}`
                              : flight?.return_date}
                          </p>
                        </div>
                      </div>
                      {/* <div className='listmain'>
                    <p className='listmainptag'>{flight?.departure_city?.split(",")[0]}-to-{flight?.destination_city?.split(",")[0]}</p>
                    <ol className='liststyle'>
                      <li>{flight?.departure_date}</li>
                      <span>|</span>
                      <li>1 Adult</li>
                      <span>|</span>
                      <li>{flight?.flight_class}</li>
                    </ol>
                  </div> */}
                    </div>

                    <div className='col-md-2 text-end'>
                      <p className="numberptag text-end">${flight.price}</p>
                      <div className="d-flex justify-content-end align-items-center">
                        <span className="total_price">{totalPrice ? "Now: " : ""}</span>
                        <MDTypography
                          variant="h5"
                          color="white"
                          className="flight_details"
                        >
                          {" "} {totalPrice}
                        </MDTypography>
                      </div>
                    </div>
                  </div>
                }
              </>
            ))
          ) : (
            <p>No flight available</p>
          )}
        </div>
      </div>
    </MDBox>
  );
}

export default FlightBooking;