import React, { useEffect, useState } from "react";
import Card from "@mui/material/Card";
import MDTypography from "components/MDTypography";
import MDBox from "components/MDBox";
import DiamondIcon from "@mui/icons-material/Diamond";
import { Button, Grid } from "@mui/material";
import BullseyeArrow from "assets/user-dash/bullseye-arrow.png";
import BasicLayout from "layouts/authentication/components/BasicLayout";
import Navbar from "layouts/Front-Page/Navbar";
import NewsletterFooter from "layouts/Front-Page/NewsletterFooter";
import { useNavigate } from "react-router-dom";
import { planDescription } from "utils/api";
import { planCreate } from "utils/api";
import { toast } from "react-toastify";

function Subscription() {

  const navigate = useNavigate()
  const [planData, setPlanData] = useState([])


  useEffect(() => {
    planDescription().then((res) => {
      setPlanData(res.data)
    }).catch((error) => {
      console.log("error", error)
    })
  }, [])

  const handleSubscrition = () => {
    planCreate().then((res) => {
      if (res.status == 200) {
        window.location.href = res.data.approval_url
      }else{
        toast.success(res?.data?.message, {
          theme : "colored",
          autoClose : 3000,
        })
      }
      
    }).catch((error) => {
      console.log(error)
    })
  }


  return (
    <BasicLayout>
      {/* <div className="pt-2">
        <Navbar />
      </div> */}

      <div className="registration_bg">
        <Grid container justifyContent="center">
          <Grid item xs={12} md={10} lg={10}>
            <MDBox>
              <div className="text-center">
                <MDTypography variant="h5" color="white">Registration</MDTypography>
              </div>
              <div>
                <Grid container justifyContent="center">
                  {/* <Grid item xs={12} md={4} p={2}>
                  <Card className="plans_card">
                    <img
                      src={BullseyeArrow}
                      width={45}
                      className="bullseye_imgs"
                    />
                    <MDTypography variant="p" mt={1}>
                      Basic
                    </MDTypography>
                    <MDTypography variant="h6">Basic</MDTypography>
                    <MDTypography variant="h1" mt={1} className="plans_name">
                      ${parseInt(planData?.[0]?.value)}<span className="for_month">for {planData?.[0]?.interval_unit}</span>
                    </MDTypography>
                    <Button onClick={()=>handleSubscrition(planData?.[0]?.plan_id)} className="get_started_butn">Get Started</Button>
                  </Card>
                </Grid> */}
                  <Grid item xs={12} md={5} p={2}>
                    <Card className="premium_card">
                      {/* <DiamondIcon className="diamond_icon" /> */}
                      {/* <div className="premiuim_section">
                      <MDTypography variant="p" color="white">
                        {planData?.[0]?.name}
                      </MDTypography>
                      <Button className="offer_butn">Best Offer</Button>
                    </div> */}
                      <MDTypography variant="p" color="white" className="for_month" mt={1}>
                        {planData?.[0]?.message && (
                          <>
                            <span
                              style={{
                                fontWeight: 'bold',
                                fontSize: '1.5rem',
                                display: 'block',
                                textAlign: 'center',
                              }}
                            >
                              {planData[0].message.split('\n')[0]}
                            </span>
                          </>
                        )}
                      </MDTypography>

                      <MDTypography variant="p" color="white" className="for_month">
                        {planData?.[0]?.message && (
                          <>
                            <span style={{
                              display: 'block',
                              textAlign: 'center',
                              margin: "5px 20px",
                              display:"flex",
                              justifyContent:"center"
                            }}>
                              {planData[0].message.split('\n')[1]}
                            </span>
                          </>
                        )}
                      </MDTypography>

                      <div className="text-center">
                        <MDTypography
                          className="plans_name"
                          variant="h1"
                          color="white"
                        >
                          ${parseInt(planData?.[0]?.price)}
                        </MDTypography>
                      </div>
                      <Button onClick={() => handleSubscrition()} variant="light" className="get_started_2">
                        Pay Now
                      </Button>
                      <MDTypography variant="p" color="white" className="for_month2" mt={2}>
                        <span style={{
                          display: 'block',
                          textAlign: 'left',
                          margin: "5px 10px"
                        }}>
                          {planData?.[0]?.message.split('\n').slice(2).join(" ")}
                        </span>
                      </MDTypography>
                    </Card>
                  </Grid>
                </Grid>

                {/* <Grid p={3} container justifyContent="center">
            <Grid item xs={12} md={8} p={2}>
              <Card className="more_plans_Card">
                <div className="more_plan_section">
                  <div>
                    <Button className="plans_quantity">10 Prints</Button>
                  </div>
                  <div className="buy_now_section">
                    <MDTypography variant="h5">$10.00</MDTypography>
                    <Button className="buy_now_butn">Buy Now</Button>
                  </div>
                </div>
                <div className="more_plan_section">
                  <div>
                    <Button className="plans_quantity">10 Prints</Button>
                  </div>
                  <div className="buy_now_section">
                    <MDTypography variant="h5">$10.00</MDTypography>
                    <Button className="buy_now_butn">Buy Now</Button>
                  </div>
                </div>
                <div className="more_plan_section">
                  <div>
                    <Button className="plans_quantity">10 Prints</Button>
                  </div>
                  <div className="buy_now_section">
                    <MDTypography variant="h5">$10.00</MDTypography>
                    <Button className="buy_now_butn">Buy Now</Button>
                  </div>
                </div>
                <div className="more_plan_section">
                  <div>
                    <Button className="plans_quantity">10 Prints</Button>
                  </div>
                  <div className="buy_now_section">
                    <MDTypography variant="h5">$10.00</MDTypography>
                    <Button className="buy_now_butn">Buy Now</Button>
                  </div>
                </div>
                <div className="more_plan_section">
                  <div>
                    <Button className="plans_quantity">10 Prints</Button>
                  </div>
                  <div className="buy_now_section">
                    <MDTypography variant="h5">$10.00</MDTypography>
                    <Button className="buy_now_butn">Buy Now</Button>
                  </div>
                </div>
                <div className="more_plan_section">
                  <div>
                    <Button className="plans_quantity">10 Prints</Button>
                  </div>
                  <div className="buy_now_section">
                    <MDTypography variant="h5">$10.00</MDTypography>
                    <Button className="buy_now_butn">Buy Now</Button>
                  </div>
                </div>
              </Card>
            </Grid>
          </Grid> */}
              </div>
            </MDBox>
          </Grid>
        </Grid>
      </div>
      {/* <Grid container justifyContent="center">
        <Grid item md={10}>
          <NewsletterFooter />
        </Grid>
      </Grid> */}
    </BasicLayout>
  );
}

export default Subscription;
