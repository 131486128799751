
import Dashboard from "layouts/dashboard";
import Tables from "layouts/tables";
import Billing from "layouts/billing";
import RTL from "layouts/rtl";
import Notifications from "layouts/notifications";
import Profile from "layouts/profile";
import PriceMonitor from "./layouts/Price Monitor/Pricr-Monitor"

// @mui icons
import Icon from "@mui/material/Icon";
import UserDashboard from "layouts/user/userDashboard";
import PassedBooking from "layouts/PassedBooking";
import Subscription from "layouts/subscription";

const userRoutes = [
  {
    type: "collapse",
    name: "Home",
    key: "dashboard",
    icon: <Icon fontSize="small" className="sidebar_icons">home</Icon>,
    route: "/dashboard",
    component: <UserDashboard />,
  },
  {
    type: "collapse",
    name: "Booking History",
    key: "history",
    icon: <Icon fontSize="small" className="sidebar_icons">Booking</Icon>,
    route: "/booking-history",
    component: <PassedBooking />,
  },
  // {
  //   type: "collapse",
  //   name: "Subscription",
  //   key: "subscription",
  //   icon: <Icon fontSize="small">subscription</Icon>,
  //   route: "/subscription",
  //   component: <Subscription />,
  // },
  // {
  //   type: "collapse",
  //   name: "Scraping Script Monitoring",
  //   key: "billing",
  //   icon: <Icon fontSize="small">receipt_long</Icon>,
  //   route: "/billing",
  //   component: <Billing />,
  // },
  // {
  //   type: "collapse",
  //   name: "Notifications",
  //   key: "notifications",
  //   icon: <Icon fontSize="small">notifications</Icon>,
  //   route: "/notifications",
  //   component: <Notifications />,
  // },
  {
    type: "collapse",
    name: "Profile",
    key: "profile",
    icon: <Icon fontSize="small" className="sidebar_icons">person</Icon>,
    route: "/profile",
    component: <Profile />,
  },
  // {
  //   type: "collapse",
  //   name: "Price Monitor",
  //   key: "Price Monitor",
  //   icon: <Icon fontSize="small">insights</Icon>,
  //   route: "/price-monitor",
  //   component: <PriceMonitor />,
  // },
];

export default userRoutes;
