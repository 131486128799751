import MDBox from "components/MDBox";
import * as React from "react";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import MDTypography from "components/MDTypography";
import { Button, Card, FormControl, InputLabel, MenuItem, Select, TextField, } from "@mui/material";
import HotelImg from "assets/user-dash/hotelimg.png";
import MDButton from "components/MDButton";
import Loader from "components/Loader";
import { Link, useNavigate } from "react-router-dom";
import { encodeIdWithType } from "utils/encoder";
import { toast } from "react-toastify";
import { updateHotelStatus } from "utils/api";
import { Modal } from "react-bootstrap";

function HotelBook({ hotels, loading, allBookingData }) {
  // const filteredFlights = hotels.map((hotel) => ({
  //   ...hotel,
  //   substitutes: hotel.substitutes.filter((substitute) =>
  //     ["PENDING", "ACCEPT", "REJECT"].includes(substitute.status)
  //   ),
  // }));

  // console.log("hotelshotels", hotels)

  const [substitute_data, setSubstitueData] = React.useState([]);
  const [selectedReason, setSelectedReason] = React.useState("");
  const [selectMessage, setSelectMesage] = React.useState("")
  const [otherReasonText, setOtherReasonText] = React.useState("");
  const [status, setStatus] = React.useState(null);
  const REASON_CHOICES = React.useMemo(() => {
    switch (status) {
      case 'ELIMINATE':
        return [
          ['HOTEL_TOO_FAR', 'Hotel Too Far Away'],
          ['NOT_ACCEPTABLE_HOTEL', 'Not Acceptable Hotel'],
          ['OTHER', 'Other'],
        ];
      case 'REJECT':
        return [
          ['WAITING', 'Waiting/Not Enough Saving'],
          ['HOTEL_TOO_FAR', 'Hotel Too Far Away'],
          ['OTHER', 'Other'],
        ];
      default:
        return [
          ['HOTEL_TOO_FAR', 'Hotel Too Far Away'],
          ['NOT_ACCEPTABLE_HOTEL', 'Not Acceptable Hotel'],
          ['OTHER', 'Other'],
          ['WAITING', 'Waiting/Not Enough Saving'],
          ['HOTEL_TOO_FAR', 'Hotel Too Far Away'],
        ];
    }
  }, [status]);

  const handleChange = (event) => {
    setSelectedReason(event.target.value);
    setSelectMesage("");
    if (event.target.value !== 'OTHER') {
      setOtherReasonText("");
    }
  };
  const navigate = useNavigate();

  const [ticketStatuses, setTicketStatuses] = React.useState({});

  const [show, setShow] = React.useState(false);


  const handleClose = () => {
    setShow(false);
    setSelectedReason("");
    setOtherReasonText("");
    setSelectMesage("");
  };

  const handleStatus = (id, status) => {
    setShow(id);
    setStatus(status);
  };

  const handleUpdateStatus = () => {
    if (!selectedReason && status !== "ACCEPT") {
      setSelectMesage("Please select any reason");
      return;
    }
    if (selectedReason === 'OTHER' && !otherReasonText.trim()) {
      setSelectMesage("Please provide a reason in the text field");
      return;
    }
    updateHotelStatus(show, {
      status: status,
      additional_information: selectedReason,
      other_reason_text: selectedReason === 'OTHER' ? otherReasonText : null
    })

      .then((res) => {
        console.log("Status update response:", res);
        console.log("Status received:", res.data?.status);
        if (res.status == 200) {
          allBookingData();

          toast.success(
            `Status ${status == "ELIMINATE"
              ? `${status?.toLowerCase()}d`
              : `${status?.toLowerCase()}ed`
            } successfully`,
            {
              theme: "colored",
            }
          );

          setShow(false);
          setSelectedReason("");
          setOtherReasonText("");
          setSelectMesage("");
        } else {
          toast.error(`Failed to ${status?.toLowerCase()} status`, {
            theme: "colored",
          });
        }
      })
      .catch((error) => {
        console.log(error);
      });
  }



  // React.useEffect(() => {
  //   setLoading(true);
  //   fetchBookings()
  //     .then(async (response) => {
  //       if (response.data.hotels.length > 0) {
  //         setLoading(true);
  //         let data = [];
  //         for (let i = 0; i < response.data.hotels.length; i++) {
  //           let item = response.data.hotels[i];

  //           let res = await fetchSubstitueHotel(item.id);
  //           if (res.data?.hotel_offers) {
  //             item.substitutes = Array.isArray(res.data?.hotel_offers)
  //               ? res.data?.hotel_offers
  //               : [];
  //           }
  //           data.push(item);
  //         }
  //         setHotels(data);
  //         setLoading(false);
  //       }
  //     })
  //     .catch((error) => {
  //       setLoading(false);
  //     });
  // }, []);

  const redirection = (id) => {
    let final_id = encodeIdWithType(id, "hotels");
    navigate(`/price-monitor/${final_id}`);
  };

  const getSaving = (item_price, ticket_price) => {
    // const itemPrice = parseFloat(item_price.replace(/[^\d.-]/g, ""));
    const ticketPrice = parseFloat(ticket_price.replace(/[^\d.-]/g, ""));
    const savings = (ticketPrice - item_price).toFixed(2);
    return parseFloat(savings);
  };

  return (
    <MDBox py={3}>
      <MDTypography variant="h6">My Bookings</MDTypography>
      {loading ? (
        <Loader />
      ) : (
        <div>
          {hotels?.length > 0
            ? hotels?.map((item, i) => {
              return (
                <Accordion className="mb-3 flight_substitutes_bg" defaultExpanded={i === 0} key={i}>
                  <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel1-content"
                    id="panel1-header"
                  >
                    <div className="airline_header row" >
                      <div className="airline_data col-md-5">
                        <img src={HotelImg} alt="airline logo" />
                        <div className="airline_title_section">
                          <MDTypography
                            variant="h6"
                            color="white"
                            className="airline_heading"
                          >
                            {item?.hotel_name}

                          </MDTypography>
                          <MDTypography
                            variant="p"
                            color="white"
                            className="hotel_address"
                          >
                            {item?.hotel_address}
                          </MDTypography>
                          <MDTypography
                            variant="p"
                            color="white"
                            className="ticket_title"
                          >
                            Check in: {item?.check_in_date}
                          </MDTypography>
                          <MDTypography
                            variant="p"
                            color="white"
                            className="ticket_title"
                          >
                            Check out: {item?.check_out_date}
                          </MDTypography>
                        </div>
                      </div>
                      <div className="col-md-4">
                        <MDTypography
                          variant="h5"
                          color="white"
                          className="flight_details"
                        >
                          {item?.guest_name}
                        </MDTypography>
                        <MDTypography
                          variant="p"
                          color="white"
                          className="ticket_title"
                        >
                          Booking reference: {item?.booking_reference}
                        </MDTypography>
                      </div>
                      <div className="col-md-3">
                        <MDTypography
                          variant="h5"
                          color="white"
                          className="flight_details"
                        >
                          {item?.rental_price}
                        </MDTypography>
                        <MDTypography
                          variant="p"
                          color="white"
                          className="ticket_title hotel_room_type"
                        >
                          {item?.room_type}
                        </MDTypography>
                      </div>
                    </div>
                  </AccordionSummary>
                  <AccordionDetails className="substitues_section">
                    <div className="substitutes my-2">
                      <MDTypography variant="h6" color="white">
                        Substitutes Tickets
                      </MDTypography>
                      {item?.substitutes && item?.substitutes?.length > 0 ? (
                        <button
                          className="view_all"
                          onClick={() => {
                            redirection(item.id);
                          }}
                        >
                          View All
                        </button>
                      ) : (
                        ""
                      )}
                    </div>
                    {loading ? (
                      <>
                        <MDTypography
                          className="text-center"
                          variant="h6"
                          color="white"
                        >
                          Loading...
                        </MDTypography>
                      </>
                    ) : (
                      <>
                        {item.substitutes?.length > 0 ? (
                          item?.substitutes?.slice(0, 3)?.map((hotel, index) => {
                            console.log(`Rendering hotel ${hotel.id}:`, {
                              status: hotel.status,
                              buttonText: hotel.status === "ELIMINATE" ? "ELIMINATED" : "ELIMINATE",
                              buttonClass: hotel.status === "ELIMINATE" ? "eliminated_butn" : "eliminate_butn"
                            });
                            return (
                              <Card className="hotel_cards my-2">
                                <div className="airline_header substitutes_card_flight row" style={{ paddingBottom: "0px !important" }}>
                                  <div className=" hotel_name_title col-md-4">
                                    <MDTypography
                                      variant="h6"
                                      className="hotel_name"
                                    >
                                      {hotel.hotel_name}
                                    </MDTypography>
                                    <MDTypography
                                      variant="p"
                                      className="hotel_address"
                                    >
                                      {hotel?.hotel_address}
                                    </MDTypography>
                                    <div className="ticket_details">
                                      <MDTypography
                                        variant="p"
                                        className="ticket_title"
                                      >
                                        Check in: {hotel.check_in_date}
                                      </MDTypography>
                                    </div>
                                    <div className="ticket_details">
                                      <MDTypography
                                        variant="p"
                                        className="ticket_title"
                                      >
                                        Check out: {hotel.check_out_date}
                                      </MDTypography>
                                    </div>


                                  </div>
                                  <div className="flight_price col-md-2">
                                    <MDTypography
                                      variant="p"
                                      className="fstptag"
                                    >
                                      ${hotel.price_total}
                                    </MDTypography>
                                    <p
                                      className="sndptag"
                                      style={{ whiteSpace: "nowrap" }}
                                    >
                                      You Can Save{" "}
                                      <span
                                        style={{
                                          color: "#61a108",
                                          fontWeight: "bold",
                                          fontSize: "14px",
                                          whiteSpace: "nowrap",
                                        }}
                                      >
                                        {hotel?.saved_amount ? `$${hotel?.saved_amount}` : ""}
                                        {/* {hotel?.saved_amount?.toFixed(2)} */}
                                        {/* $ {getSaving(hotel?.price?.total, item.rental_price)} */}
                                      </span>
                                    </p>
                                  </div>
                                  <div className="col-md-2">
                                    <MDTypography
                                      variant="p"
                                      className="ticket_title"
                                    >
                                      {hotel.adults} Adult
                                    </MDTypography>
                                    <span className="mx-1">|</span>
                                    <MDTypography
                                      variant="p"
                                      className="ticket_title text-capitalize"
                                    >
                                      {hotel.room_type_estimated.bedType}

                                    </MDTypography>
                                  </div>
                                  {hotel?.status == "PENDING" ? (
                                    <div className="col-md-4 d-flex airlinetkt">
                                      <div className="col-md-12">
                                        <div className="ticket_detail_butns">
                                          <MDButton
                                            className="accept_butn"
                                            onClick={() =>
                                              handleStatus(hotel.id, "ACCEPT")
                                            }
                                            disabled={
                                              ticketStatuses[hotel.id] ||
                                              hotel.status !== "PENDING"
                                            }
                                          >
                                            ACCEPT
                                          </MDButton>
                                          <MDButton
                                            className={`reject_butn
                                            `}
                                            onClick={() =>
                                              handleStatus(hotel.id, "REJECT")
                                            }
                                            disabled={
                                              ticketStatuses[hotel.id] ||
                                              hotel.status !== "PENDING"
                                            }
                                          >
                                            REJECT
                                          </MDButton>
                                          <MDButton
                                            className="eliminate_butn"
                                            onClick={() =>
                                              handleStatus(
                                                hotel.id,
                                                "ELIMINATE"
                                              )
                                            }
                                            disabled={
                                              ticketStatuses[hotel.id] ||
                                              hotel.status !== "PENDING"
                                            }
                                          >
                                            ELIMINATE
                                          </MDButton>
                                        </div>
                                      </div>
                                    </div>
                                  ) : (
                                    <>
                                      <div className="col-md-4 d-flex justify-content-end  airlinetkt">
                                        <div className="col-md-12 text-end">
                                          <div className="ticket_detail_butns">
                                            <MDButton
                                              className={`accept_butn ${hotel?.status == "ACCEPT" &&
                                                "accepted_butn"
                                                }`}
                                              disabled={hotel?.status == "ACCEPT"}
                                              onClick={() =>
                                                handleStatus(
                                                  hotel.id,
                                                  "ACCEPT"
                                                )
                                              }
                                            >
                                              {hotel?.status == "ACCEPT"
                                                ? "ACCEPTED"
                                                : "ACCEPT"}
                                            </MDButton>

                                            <MDButton
                                              className={`${hotel?.status == "REJECT"
                                                ? "rejected_butn"
                                                : "reject_butn"
                                                }`}
                                              disabled={hotel?.status == "REJECT"}
                                              onClick={() =>
                                                handleStatus(
                                                  hotel.id,
                                                  "REJECT"
                                                )
                                              }
                                            >
                                              {hotel?.status == "REJECT"
                                                ? "REJECTED"
                                                : "REJECT"}
                                            </MDButton>

                                            <MDButton
                                              className={`${hotel?.status == "ELIMINATE"
                                                ? "eliminated_butn"
                                                : "eliminate_butn"
                                                }`}
                                              disabled={hotel?.status == "ELIMINATE"}
                                              onClick={() =>
                                                handleStatus(
                                                  hotel.id,
                                                  "ELIMINATE"
                                                )
                                              }
                                            >
                                              {hotel?.status == "ELIMINATE"
                                                ? "ELIMINATED"
                                                : "ELIMINATE"}
                                            </MDButton>

                                          </div>
                                        </div>
                                      </div>
                                    </>
                                  )}
                                </div>
                                <div className="row">
                                  <div className="col-md-5">
                                    <div className="ticket_details">
                                      <MDTypography
                                        variant="p"
                                        className="ticket_title"
                                      >
                                        <span
                                          style={{
                                            color: "#61a108",
                                            fontWeight: "bold",
                                            fontSize: "13px",
                                            whiteSpace: "nowrap",
                                          }}
                                        >
                                          {hotel?.match_percentage}%
                                        </span>Matched
                                      </MDTypography>
                                    </div>

                                    <ul className="row">
                                      {hotel?.percentage_changes?.split(",")?.map((percent, i) => {

                                        return (
                                          <li
                                            className="col-md-12 hotel_desc_list"
                                            key={i}
                                          >
                                            {percent}
                                          </li>
                                        );

                                      })}
                                    </ul>
                                  </div>

                                  <div className="col-md-7">
                                    <div className="ticket_details">
                                      <MDTypography
                                        variant="p"
                                        className="ticket_title"
                                      >Amenities:
                                      </MDTypography>
                                    </div>
                                    <ul className="row">
                                      {hotel.description_text
                                        ?.split(",")
                                        .map((value) => {
                                          if (value !== "") {
                                            return (
                                              <li
                                                className="col-md-6 hotel_desc_list"
                                                key={index}
                                              >
                                                {value}
                                              </li>
                                            );
                                          }
                                        })}
                                    </ul>
                                  </div>
                                </div>

                              </Card>
                            );
                          })
                        ) : (
                          <p className="no_substitute">
                            No substitute available
                          </p>
                        )}
                      </>
                    )}
                  </AccordionDetails>
                </Accordion>
              );
            })
            : ""}
        </div>
      )}

      <Modal show={show} onHide={handleClose} centered>
        {/* <Modal.Header closeButton>
        </Modal.Header> */}
        <Modal.Body>
          <div className="text-center p-4">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="40"
              height="40"
              fill="currentColor"
              className="bi bi-check-circle-fill status_icon"
              viewBox="0 0 16 16"
            >
              <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0m-3.97-3.03a.75.75 0 0 0-1.08.022L7.477 9.417 5.384 7.323a.75.75 0 0 0-1.06 1.06L6.97 11.03a.75.75 0 0 0 1.079-.02l3.992-4.99a.75.75 0 0 0-.01-1.05z" />
            </svg>
            <MDTypography variant="h5">Are you Sure?</MDTypography>
            {status == "ACCEPT" &&
              <MDTypography variant="p" className="delete_msj">
                We are glad to know, you would like to Accept this Offer!
              </MDTypography>
            }

            {status == "REJECT" &&
              <MDTypography variant="p" className="delete_msj">
                Are you sure? You would like to Reject this offer.
              </MDTypography>
            }

            {status == "ELIMINATE" &&
              <MDTypography variant="p" className="delete_msj">
                Are you sure? You would like to Eliminate this offer.
              </MDTypography>
            }

            {status !== "ACCEPT" &&
              <div className="my-3 text-left">
                <FormControl fullWidth>
                  <InputLabel id="reason-select-label">Select Reason</InputLabel>
                  <Select
                    labelId="reason-select-label"
                    value={selectedReason}
                    onChange={handleChange}
                    label="Select Reason"
                    className="select_reason_input"
                  >
                    {REASON_CHOICES?.map((reason) => (
                      <MenuItem key={reason[0]} value={reason[0]}>
                        {reason[1]}
                      </MenuItem>
                    ))}
                    {selectedReason === 'OTHER' && (
                      <TextField
                        fullWidth
                        label="Please specify the reason"
                        variant="outlined"
                        value={otherReasonText}
                        onChange={(e) => {
                          setOtherReasonText(e.target.value);
                          setSelectMesage(""); // Clear any previous error messages
                        }}
                        className="other_reason_input"
                        multiline
                        rows={3}
                      />
                    )}
                  </Select>
                </FormControl>
                <div className="error_message my-1">{selectMessage}</div>
              </div>
            }

            <Button
              variant="secondary"
              className="cancel_butn"
              onClick={handleClose}
            >
              Cancel
            </Button>
            <Button
              variant="primary"
              className="delete_butn"
              onClick={() => {
                handleUpdateStatus();
              }}
            >
              Update
            </Button>
          </div>
        </Modal.Body>
      </Modal>
    </MDBox>
  );
}

export default HotelBook;
