import MDBox from "components/MDBox";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import MDTypography from "components/MDTypography";
import FlightBooking from "./Components/FlightBooking";
import SubstituteTicket from "./Components/SubstituteTicket";
import { useEffect, useState } from "react";
import { fetchFlightOffers, fetchBookingsById } from "utils/api";
import { useLocation, useParams } from "react-router-dom";
import Loader from "components/Loader";
import { decodeIdWithType } from "utils/encoder";
import { fetchSubstitueHotel } from "utils/api";
import HotelBooking from "./Components/HotelBooking";
import { pastFlightsDetails } from "utils/api";
import { fetchPastBookingsById } from "utils/api";
import { flightOffersById } from "utils/api";

function PriceMonitor() {
  const params = useParams();
  const location = useLocation()
  const [loadingBookings, setLoadingBookings] = useState(true);
  const [error, setError] = useState(null);
  const { type, id } = decodeIdWithType(params.id);
  const [booking_data, setBookingData] = useState([]);
  const [substitutes, setSubstitutes] = useState([]);
  const [totalPrice, setTotalPrice] = useState("")
  const [same_sub, setSameSub] = useState("")

  useEffect(() => {
    fetchAllSubstitute();
  }, [id]);

  const fetchAllSubstitute = () => {
    if (location?.state?.passBooking == "pass_booking") {
      if (id) {
        setLoadingBookings(true);
        fetchPastBookingsById(id)
          .then((response) => {
            if (response.status === 200) {
              const { cars, flights, hotels } = response?.data;
              if (cars.length > 0 || flights.length > 0 || hotels.length > 0) {
                if (type === "flights") {
                  setBookingData(response?.data?.flights || []);
                  flightOffersById(id).then((resp) => {
                    console.log("same_flight_sub", resp)
                    if (resp.data.error) {
                      setSameSub(null)
                    } else {
                      setTotalPrice(resp?.data?.total_price)
                      setSameSub(resp?.data)
                    }

                  }).catch((error) => {
                    console.log(error)
                  })

                  fetchFlightOffers(id).then((res) => {
                    console.log("res.datares.data", res)
                    setTimeout(() => {
                      setLoadingBookings(false);
                    }, 3000)
                    if (res?.data) {
                      setSubstitutes(Array.isArray(res?.data?.paired_offers) ? res?.data?.paired_offers : []);
                    } else {
                      setSubstitutes([]);
                    }
                  });
                } else if (type === "hotels") {
                  setBookingData(response.data.hotels || []);
                  fetchSubstitueHotel(id).then((res) => {
                    setLoadingBookings(false);
                    if (res.data) {
                      setSubstitutes(Array.isArray(res.data) ? res.data : []);
                    } else {
                      setSubstitutes([]);
                    }
                  });
                } else if (type === "cars") {
                  setBookingData(response.data.cars || []);
                  setSubstitutes([]);
                  setLoadingBookings(false);
                }
              } else {
                setError(new Error("No booking data found"));
                return;
              }
            } else {
              setError(
                new Error(
                  "Failed to fetch bookings. Status: " +
                  response.status +
                  ". Message: " +
                  (response.data?.message || "No message")
                )
              );
            }
          })
          .catch((error) => setError(error))
          .finally(() => setLoadingBookings(false));
      } else {
        setError(new Error("No flight ID provided."));
        setLoadingBookings(false);
      }
    } else {
      if (id) {
        setLoadingBookings(true);
        fetchBookingsById(id)
          .then((response) => {
            if (response.status === 200) {
              const { cars, flights, hotels } = response?.data;
              if (cars.length > 0 || flights.length > 0 || hotels.length > 0) {
                if (type === "flights") {
                  setBookingData(response.data.flights || []);
                  flightOffersById(id).then((resp) => {
                    console.log("same_flight_sub", resp)
                    if (resp.data.error) {
                      setSameSub(null)
                    } else {
                      setTotalPrice(resp?.data?.total_price)
                      setSameSub(resp?.data)
                    }
                  }).catch((error) => {
                    console.log(error)
                  })

                  fetchFlightOffers(id).then((res) => {
                    console.log("res.datares.data", res)
                    setTimeout(() => {
                      setLoadingBookings(false);
                    }, 3000)
                    if (res.data) {
                      setSubstitutes(Array.isArray(res.data.paired_offers) ? res.data.paired_offers : []);
                    } else {
                      setSubstitutes([]);
                    }
                  });
                } else if (type === "hotels") {
                  setBookingData(response.data.hotels || []);
                  fetchSubstitueHotel(id).then((res) => {
                    console.log("pppppppppppppppppp", res);
                    setLoadingBookings(false);
                    if (res.data) {
                      setSubstitutes(Array.isArray(res.data) ? res.data : []);
                    } else {
                      setSubstitutes([]);
                    }
                  });
                } else if (type === "cars") {
                  setBookingData(response.data.cars || []);
                  setSubstitutes([]);
                  setLoadingBookings(false);
                }
              } else {
                setError(new Error("No booking data found"));
                return;
              }
            } else {
              setError(
                new Error(
                  "Failed to fetch bookings. Status: " +
                  response.status +
                  ". Message: " +
                  (response.data?.message || "No message")
                )
              );
            }
          })
          .catch((error) => setError(error))
          .finally(() => setLoadingBookings(false));
      } else {
        setError(new Error("No flight ID provided."));
        setLoadingBookings(false);
      }
    }

  };

  const isLoading = loadingBookings;

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox py={3}>
        {isLoading ? (
          <Loader />
        ) : (
          <>
            {type === "flights" ? (
              <>
                <FlightBooking details={booking_data} totalPrice={totalPrice} />
                {substitutes.length > 0 ? (
                  <SubstituteTicket
                    details={booking_data}
                    substitutes={substitutes}
                    loading={loadingBookings}
                    passBooking={location?.state?.passBooking}
                    fetchAllSubstitute={fetchAllSubstitute}
                    current_price={booking_data[0].price}
                    same_flight_sub={same_sub}
                  />
                ) : (
                  <MDTypography variant="h6" color="textSecondary">
                    No Substitutes Found
                  </MDTypography>
                )}
              </>
            ) : type === "hotels" ? (
              <>
                <HotelBooking
                  fetchAllSubstitute={fetchAllSubstitute}
                  booking={booking_data}
                  passBooking={location?.state?.passBooking}
                  substitutes={substitutes}
                />
              </>
            ) : (
              <></>
            )}
          </>
        )}
      </MDBox>
    </DashboardLayout>
  );
}

export default PriceMonitor;
