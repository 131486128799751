import MDBox from "components/MDBox";
import * as React from "react";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import MDTypography from "components/MDTypography";
import AirlineLogo from "assets/user-dash/airlineLogo.png";
import {
  Button,
  Card,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from "@mui/material";
import FlightImg from "assets/user-dash/flight.png";
import MDButton from "components/MDButton";
import airlinrlogo from "assets/img/airlinrlogo.png";
import plane from "assets/img/plane.svg";
import { fetchBookings } from "utils/api";
import { fetchFlightOffers } from "utils/api";
import Loader from "components/Loader";
import { Link, useNavigate } from "react-router-dom";
import { encodeIdWithType } from "utils/encoder";
import timelineItem from "examples/Timeline/TimelineItem/styles";
import { updateSubstituteStatus } from "utils/api";
import { toast } from "react-toastify";
import { Modal } from "react-bootstrap";
function BookingCard({ flights, loading, allBookingData }) {

  const navigate = useNavigate();
  const [ticketStatuses, setTicketStatuses] = React.useState({});
  const [show, setShow] = React.useState(false);

  const [status, setStatus] = React.useState(null);
  const [selectedReason, setSelectedReason] = React.useState("");
  const [selectMessage, setSelectMesage] = React.useState("");
  const [otherReasonText, setOtherReasonText] = React.useState("");
  const [siteUrl, setSiteUrl] = React.useState("")
  const REASON_CHOICES = React.useMemo(() => {
    switch (status) {
      case "ELIMINATE":
        return [
          ["TIME_DIFFERENCE_TOO_GREAT", "Time difference too great"],
          ["AIRLINE_UNACCEPTABLE", "Airline is unacceptable"],
          ["OTHER", "Other"],
        ];
      case "REJECT":
        return [
          ["WAITING", "Waiting"],
          ["POLICY_RESTRICTION", "Policy Restriction"],
          ["OTHER", "Other"],
        ];
      default:
        return [
          ["TIME_DIFFERENCE_TOO_GREAT", "Time difference too great"],
          ["AIRLINE_UNACCEPTABLE", "Airline is unacceptable"],
          ["WAITING", "Waiting"],
          ["POLICY_RESTRICTION", "Policy Restriction"],
          ["OTHER", "Other"],
        ];
    }
  }, [status]);

  const handleChange = (event) => {
    setSelectedReason(event.target.value);
    setSelectMesage("");
    if (event.target.value !== "OTHER") {
      setOtherReasonText("");
    }
  };

  const handleClose = () => {
    setShow(false);
    setSelectedReason("");
    setOtherReasonText("");
    setSelectMesage("");
  };

  const handleStatus = (id, status, url) => {
    setShow(id);
    setStatus(status);
    setSiteUrl(url)
  };

  const handleAcceptSite = () => {
    window.open(siteUrl, '_blank')
    setShow(false)
  }

  const handleUpdateStatus = () => {
    if (!selectedReason && status !== "ACCEPT" && status !== "REINSTATE") {
      setSelectMesage("Please select any reason");
      return;
    }

    if (selectedReason === "OTHER" && !otherReasonText.trim()) {
      setSelectMesage("Please provide a reason in the text field");
      return;
    }

    updateSubstituteStatus(show, {
      status: status == "REINSTATE" ? "ACCEPT" : status,
      additional_information: selectedReason,
      other_reason_text: selectedReason === "OTHER" ? otherReasonText : null,
    })
      .then((res) => {
        if (res.status == 200) {
          allBookingData();
          toast.success(
            `Status ${status == "ELIMINATE"
              ? `${status?.toLowerCase()}d`
              : `${status?.toLowerCase()}ed`
            } successfully`,
            {
              theme: "colored",
            }
          );
          setShow(false);
          setSelectedReason("");
          setOtherReasonText("");
          setSelectMesage("");
        } else {
          toast.error(`Failed to ${status?.toLowerCase()} status`, {
            theme: "colored",
          });
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const getTime = (departure, arrival) => {
    const formatDateTime = (dateTimeStr) => {
      // Check if dateTimeStr is null, undefined, or not a string
      if (!dateTimeStr || typeof dateTimeStr !== 'string') {
        console.warn('Invalid date time string:', dateTimeStr);
        return null;
      }

      // Split the date time string, with error handling
      const parts = dateTimeStr.split(" ");
      if (parts.length < 3) {
        console.warn('Unexpected date time format:', dateTimeStr);
        return null;
      }

      const [date, time, modifier] = parts;

      // Additional checks for time and modifier
      if (!time || !modifier) {
        console.warn('Missing time or modifier:', dateTimeStr);
        return null;
      }

      let [hours, minutes] = time.split(":");
      if (!hours || !minutes) {
        console.warn('Invalid time format:', time);
        return null;
      }

      // Convert hours based on modifier
      hours = parseInt(hours, 10);
      if (modifier === "PM" && hours !== 12) {
        hours += 12;
      } else if (modifier === "AM" && hours === 12) {
        hours = 0;
      }

      try {
        return new Date(`${date}T${hours.toString().padStart(2, '0')}:${minutes}:00`);
      } catch (error) {
        console.warn('Error creating date:', error);
        return null;
      }
    };

    // Format departure and arrival dates
    const departureDate = formatDateTime(departure);
    const arrivalDate = formatDateTime(arrival);

    // If either date is invalid, return an empty string
    if (!departureDate || !arrivalDate) {
      return '';
    }

    // Calculate time difference
    const differenceInMilliseconds = arrivalDate - departureDate;
    const hours = Math.floor(differenceInMilliseconds / (1000 * 60 * 60));
    const minutes = Math.floor(
      (differenceInMilliseconds % (1000 * 60 * 60)) / (1000 * 60)
    );

    return `${hours}hr ${minutes}min`;
  };


  const getSaving = (item_price, ticket_price) => {
    const itemPrice = parseFloat(item_price.replace(/[^\d.-]/g, ""));
    const ticketPrice = parseFloat(ticket_price.replace(/[^\d.-]/g, ""));
    const savings = (itemPrice - ticketPrice).toFixed(2);
    return parseFloat(savings);
  };

  const formatDate = (dateString) => {
    const date = new Date(dateString);
    const options = { month: "short", day: "numeric" };
    const formattedDate = date.toLocaleDateString("en-US", options);
    return formattedDate.replace(",", "");
  };

  const redirection = (id) => {
    let final_id = encodeIdWithType(id, "flights");
    navigate(`/price-monitor/${final_id}`);
  };

  return (
    <MDBox py={3}>
      <MDTypography variant="h6">My Bookings</MDTypography>
      {loading ? (
        <Loader />
      ) : (
        <div>
          {flights?.length > 0 ?
            flights?.map((item, i) => (
              <Accordion
                className="mb-3 flight_substitutes_bg"
                sx={{
                  ...(!(item?.substitutes && item?.substitutes?.length > 0) && {
                    backgroundColor: "#f5f5f5",
                    opacity: 0.7,
                  }),
                }}
                defaultExpanded={i === 0}
                key={i}
              >
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel1-content"
                  id="panel1-header"
                >
                  {
                    item?.children?.length > 0 ?
                      <div className="airline_header row justify-content-start">
                        <div className="airline_data col-6 col-lg-3">
                          <img src={AirlineLogo} alt="airline logo" />
                          <div className="airline_title_section">
                            <MDTypography
                              variant="h6"
                              color="white"
                              className="airline_heading"
                            >
                              {item?.airline}
                            </MDTypography>
                            <div>
                              {item?.children?.length > 0 ? (
                                <p
                                  style={{ color: "white", marginBottom: "0px" }}
                                  className="ticket_title"
                                >
                                  Round Trip
                                </p>
                              ) : (
                                <p
                                  style={{ color: "white", marginBottom: "0px" }}
                                  className="ticket_title"
                                >
                                  One Way

                                </p>
                              )}
                            </div>
                          </div>
                        </div>
                        <div className="col-6 col-lg-2 text-end">
                          <MDTypography
                            variant="h5"
                            color="white"
                            className="flight_details"
                          >
                            ${item?.price}
                          </MDTypography>

                          <div className="d-flex justify-content-end mt-2 align-items-center">
                            <span className="total_price">{item?.same_flight_sub?.total_price ? "Now: " : ""}</span>
                            <MDTypography
                              variant="h5"
                              color="white"
                              className="flight_details"
                            >
                              {" "} {item?.same_flight_sub?.total_price}
                            </MDTypography>
                          </div>

                        </div>
                        <div className="ticket_detail_heading col-6 col-lg-3">

                          <div className="">
                            <div>
                              <MDTypography variant="h6" color="white" className="mb-5">
                                Departure
                              </MDTypography>
                              <MDTypography variant="h6" color="white" className="mb-3">
                                {item?.departure_city?.split(",")[0]}-to-
                                {item?.destination_city?.split(",")[0]}
                              </MDTypography>
                              <p
                                style={{ color: "white", marginBottom: "0px" }}
                                className="ticket_title"
                              >
                                {`${item?.flight_number} | 1 Adult | ${item?.flight_class}`}
                              </p>
                              <div>
                                <p
                                  variant="p"
                                  color="white"
                                  className="ticket_title pt-1"
                                >
                                  {item?.departure_date} {item?.departure_time}
                                </p>
                              </div>
                              <div>
                                <p
                                  variant="p"
                                  color="white"
                                  className="ticket_title"
                                >
                                  {item?.return_date && item?.Arrives_time
                                    ? `${item.return_date} ${item.Arrives_time}`
                                    : item?.return_date}
                                </p>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="ticket_detail_heading col-6 col-lg-4">
                          <div>
                            {item.children && item.children.length > 0 && (
                              <div>
                                {item?.children?.map((childFlight, index) => (
                                  <div key={index} className="child_flight_info">
                                    <MDTypography variant="h6" color="white" className="mb-5">
                                      Return
                                    </MDTypography>
                                    <MDTypography
                                      variant="h6"
                                      color="white"
                                      style={{ marginBottom: "20px" }}
                                    >
                                      {childFlight?.departure_city?.split(",")[0]} -to-{" "}
                                      {childFlight?.destination_city?.split(",")[0]}
                                    </MDTypography>
                                    <MDTypography
                                      variant="p"
                                      color="white"
                                      className="ticket_title"
                                    >
                                      {`${childFlight?.flight_number} | ${item?.flight_class}`}
                                    </MDTypography>

                                    <div>
                                      <MDTypography
                                        variant="p"
                                        color="white"
                                        className="ticket_title"
                                      >
                                        {childFlight?.departure_date} {childFlight?.departure_time}
                                      </MDTypography>
                                    </div>
                                    <div>
                                      <MDTypography
                                        variant="p"
                                        color="white"
                                        className="ticket_title"
                                      >
                                        {childFlight?.return_date && childFlight?.Arrives_time
                                          ? `${childFlight.return_date} ${childFlight.Arrives_time}`
                                          : childFlight?.return_date}
                                      </MDTypography>
                                    </div>
                                  </div>

                                ))}
                              </div>
                            )}
                          </div>
                        </div>
                      </div>
                      :
                      <div className="airline_header row justify-content-start">
                        <div className="airline_data col-md-4">
                          <img src={AirlineLogo} alt="airline logo" />
                          <div className="airline_title_section">
                            <MDTypography
                              variant="h6"
                              color="white"
                              className="airline_heading"
                            >
                              {item?.airline}
                            </MDTypography>
                            <div>
                              {item?.children?.length > 0 ? (
                                <MDTypography
                                  variant="p"
                                  color="white"
                                  className="ticket_title"
                                >
                                  Round Trip
                                </MDTypography>
                              ) : (
                                <MDTypography
                                  variant="p"
                                  color="white"
                                  className="ticket_title"
                                >
                                  One Way
                                </MDTypography>
                              )}

                            </div>
                          </div>
                        </div>
                        <div className="col-md-2 text-end">
                          <MDTypography
                            variant="h5"
                            color="white"
                            className="flight_details"
                          >
                            ${item?.price}
                          </MDTypography>
                          <div className="d-flex justify-content-end mt-2 align-items-center">
                            <span className="total_price">{item?.same_flight_sub?.total_price ? "Now: " : ""}</span>
                            <MDTypography
                              variant="h5"
                              color="white"
                              className="flight_details"
                            >
                              {" "} {item?.same_flight_sub?.total_price}
                            </MDTypography>
                          </div>
                        </div>
                        <div className="ticket_detail_heading col-md-6">
                          <div>
                            <MDTypography variant="h6" color="white" className="mb-3">
                              {item?.departure_city?.split(",")[0]}-to-
                              {item?.destination_city?.split(",")[0]}
                            </MDTypography>
                            <MDTypography
                              variant="p"
                              color="white"
                              className="ticket_title"
                            >
                              {`${item?.flight_number} | 1 Adult | ${item?.flight_class}`}
                            </MDTypography>
                            <div>
                              <MDTypography
                                style={{ color: "white", marginBottom: "0px" }}
                                className="ticket_title pt-1"
                              >
                                {item?.departure_date} {item?.departure_time}
                              </MDTypography>
                            </div>
                            <div>
                              <MDTypography
                                style={{ color: "white", marginBottom: "0px" }}
                                className="ticket_title"
                              >
                                {item?.return_date && item?.Arrives_time
                                  ? `${item.return_date} ${item.Arrives_time}`
                                  : item?.return_date}
                              </MDTypography>
                            </div>
                          </div>
                        </div>
                      </div>
                  }

                </AccordionSummary>

                <AccordionDetails className="substitues_section">
                  <div className="">
                    <div className="substitutes my-2">
                      <MDTypography variant="h6" color="white">
                        Substitutes Tickets
                      </MDTypography>

                      {(item?.substitutes?.length > 0 || item?.same_flight_sub?.outbound_offer || item?.same_flight_sub?.return_offer) ?
                        (
                          <button
                            className="view_all"
                            onClick={() => redirection(item?.id)}
                          >
                            View All
                          </button>
                        ) : (
                          <MDTypography variant="h6" color="white">No substitutes available</MDTypography>
                        )}
                    </div>
                    {loading ? (
                      <>
                        <MDTypography
                          className="text-center"
                          variant="h6"
                          color="white"
                        >
                          Loading...
                        </MDTypography>
                      </>
                    ) : (
                      <>


                        {item?.same_flight_sub &&
                          <div
                            className="container-fluid p-3 mb-3 subtiktmain"
                          >
                            {
                              item?.same_flight_sub?.return_offer ?
                                <>
                                  <div className="row align-items-center">
                                    <div className="col-md-9">
                                      <div
                                        className="row align-items-center"
                                        style={{ alignItems: "center" }}
                                      >
                                        <div className={`col-md-6 d-flex airlinetkt align-items-center`}
                                        >
                                          <div className="row align-items-center"
                                            style={{
                                              width: "100%",
                                              marginRight: "0px",
                                            }}>

                                            <div
                                              className="col-md-8 d-flex"
                                              style={{ alignItems: "center" }}
                                            >
                                              <img
                                                src={airlinrlogo}
                                                alt=""
                                                className="img-fluid"
                                              />
                                              <div className="ticktnam mx-2">
                                                <p className="ticktptag12">
                                                  {item?.same_flight_sub?.outbound_offer?.airline_name ? item?.same_flight_sub?.outbound_offer?.airline_name : ""}
                                                </p>
                                                <p className="flight_class_name">
                                                  {item?.flight_class ? item?.flight_class : ""}
                                                </p>
                                                <p className="flight_class_name">
                                                  {`${item?.same_flight_sub?.outbound_offer?.airline_code}${item?.same_flight_sub?.outbound_offer?.flight_number}`}
                                                </p>
                                                <p
                                                  className="ticktptag21"
                                                  style={{ fontSize: "13px", marginBottom: "0px !important" }}
                                                >
                                                  <span
                                                    style={{
                                                      color: "#61a108",
                                                      fontWeight: "bold",
                                                      fontSize: "13px",
                                                      whiteSpace: "nowrap",
                                                    }}
                                                  >
                                                    {item?.same_flight_sub?.outbound_offer.match_percentage}%
                                                  </span>
                                                  &nbsp;Matched
                                                </p>
                                                {/* <p className="ticktptag21" style={{ fontSize: "11px" }}>
                                                   {item?.same_flight_sub?.outbound_offer.percentage_changes}%
                                                 </p> */}
                                              </div>


                                            </div>
                                            <div
                                              className="col-md-4"
                                            >
                                              <p className="depature-heading">Depature</p>
                                              <p className="depature_code d-flex justify-content-center">    ${item?.same_flight_sub?.outbound_offer?.price}</p>
                                              {/* <p
                                               className="sndptag mb-0"
                                               style={{ whiteSpace: "nowrap", marginBottom: "0px !important" }}
                                             >
                                               You Can Save{" "}
                                               <span
                                                 style={{
                                                   color: "#61a108",
                                                   fontWeight: "bold",
                                                   fontSize: "13px",
                                                   whiteSpace: "nowrap",
                                                 }}
                                               >
                                                 {item?.same_flight_sub?.outbound_offer?.saved_amount}
 
                                               </span>
                                             </p> */}

                                            </div>
                                          </div>
                                        </div>
                                        <div className={`col-md-6 `}>

                                          <div className="Dtime">
                                            <div className="Dtimelist">

                                              <ul className="ul-list">


                                                <p className="ullist">
                                                  {
                                                    item?.same_flight_sub?.outbound_offer?.departure_date?.split(
                                                      "T"
                                                    )?.[0]
                                                  }
                                                  <span className="depature_time">
                                                    {`${item?.same_flight_sub?.outbound_offer?.departure_time?.split(
                                                      ":"
                                                    )?.[0]
                                                      }:${item?.same_flight_sub?.outbound_offer?.departure_time?.split(
                                                        ":"
                                                      )?.[1]
                                                      }`}
                                                  </span>
                                                </p>

                                                <p className="depature_code">
                                                  {item?.same_flight_sub?.outbound_offer?.departure_iata_code}
                                                </p>

                                              </ul>
                                            </div>
                                            <div className="Dtimelist">
                                              <ul style={{ padding: "0" }}>
                                                <img
                                                  src={plane}
                                                  alt="Custom Plane"
                                                  width="68px"
                                                  style={{ marginTop: "20px" }}
                                                />
                                                <li className="ullist">
                                                  {getTime(
                                                    item?.same_flight_sub?.outbound_offer?.departure_time,
                                                    item?.same_flight_sub?.outbound_offer?.arrival_time
                                                  )}
                                                </li>
                                              </ul>
                                            </div>
                                            <div className="Dtimelist">
                                              <ul className="ul-list">
                                                <p className="ullist">
                                                  {
                                                    item?.same_flight_sub?.outbound_offer?.arrival_date?.split(
                                                      "T"
                                                    )?.[0]
                                                  }
                                                  <span className="depature_time">
                                                    {`${item?.same_flight_sub?.outbound_offer?.arrival_time?.split(
                                                      ":"
                                                    )?.[0]
                                                      }:${item?.same_flight_sub?.outbound_offer?.arrival_time?.split(
                                                        ":"
                                                      )?.[1]
                                                      }`}
                                                  </span>
                                                </p>

                                                <p className="depature_code">
                                                  {item?.same_flight_sub?.outbound_offer?.arrival_iata_code}
                                                </p>

                                              </ul>
                                            </div>
                                          </div>

                                          <div>
                                            <p className="number_stops">Number of stops : {item?.same_flight_sub?.outbound_offer?.number_of_stops}</p>
                                          </div>
                                        </div>

                                      </div>
                                      <div className="px-5">
                                        <ul className="row mt-2">
                                          {item?.same_flight_sub?.outbound_offer?.percentage_changes?.split(",")?.map((value, index) => {
                                            if (value !== "") {
                                              return (
                                                <li
                                                  className="col-md-12 hotel_desc_list"
                                                  key={index}
                                                >
                                                  {value}
                                                </li>
                                              );
                                            }
                                          })}
                                        </ul>
                                      </div>
                                      <div
                                        className="row align-items-center"
                                        style={{ alignItems: "center" }}
                                      >
                                        <div className={`col-md-6 d-flex airlinetkt align-items-center`}
                                        >
                                          <div className="row d-flex align-items-center"
                                            style={{
                                              width: "100%",
                                              marginRight: "0px",
                                            }}>
                                            <div
                                              className="col-md-8 d-flex"
                                              style={{ alignItems: "center" }}
                                            >
                                              <img
                                                src={airlinrlogo}
                                                alt=""
                                                className="img-fluid"
                                              />
                                              <div className="ticktnam mx-2">
                                                <p className="ticktptag12">
                                                  {item?.same_flight_sub?.return_offer?.airline_name}
                                                </p>
                                                <p className="flight_class_name">
                                                  {item?.flight_class}
                                                </p>
                                                <p className="flight_class_name">
                                                  {`${item?.same_flight_sub?.return_offer?.airline_code}${item?.same_flight_sub?.return_offer?.flight_number}`}
                                                </p>
                                                <p
                                                  className="ticktptag21"
                                                  style={{ fontSize: "13px", marginBottom: "0px !important" }}
                                                >
                                                  <span
                                                    style={{
                                                      color: "#61a108",
                                                      fontWeight: "bold",
                                                      fontSize: "13px",
                                                      whiteSpace: "nowrap",
                                                    }}
                                                  >
                                                    {item?.same_flight_sub?.return_offer?.match_percentage}%
                                                  </span>
                                                  &nbsp;Matched
                                                </p>
                                                {/* <p className="ticktptag21" style={{ fontSize: "11px" }}>
                                                   {item?.same_flight_sub?.return_offer?.percentage_changes}%
                                                 </p> */}
                                              </div>


                                            </div>
                                            <div
                                              className="col-md-4 text-end"
                                            >
                                              <p className="depature-heading">Return</p>
                                              <p className="depature_code d-flex justify-content-center"> {`${item?.same_flight_sub?.return_offer && `$${item?.same_flight_sub?.return_offer?.price}`}`}</p>
                                              {/* <p
                                               className="sndptag mb-0"
                                               style={{ whiteSpace: "nowrap", marginBottom: "0px !important" }}
                                             >
                                               You Can Save{" "}
                                               <span
                                                 style={{
                                                   color: "#61a108",
                                                   fontWeight: "bold",
                                                   fontSize: "13px",
                                                   whiteSpace: "nowrap",
                                                 }}
                                               >
                                                 {item?.same_flight_sub?.return_offer?.saved_amount}
 
                                               </span>
                                             </p> */}
                                            </div>
                                          </div>
                                        </div>
                                        <div className={`col-md-6 `}>
                                          <div className="Dtime">
                                            <div className="Dtimelist">
                                              <ul className="ul-list">
                                                <p className="ullist">
                                                  {item?.same_flight_sub?.return_offer?.departure_date?.split("T")?.[0]}
                                                  <span className="depature_time">
                                                    {`${item?.same_flight_sub?.return_offer?.departure_time?.split(":")?.[0]}:${item?.same_flight_sub?.return_offer?.departure_time?.split(":")?.[1]}`}
                                                  </span>
                                                </p>
                                                <p className="depature_code">
                                                  {item?.same_flight_sub?.return_offer?.departure_iata_code}
                                                </p>
                                              </ul>
                                            </div>
                                            <div className="Dtimelist">
                                              <ul style={{ padding: "0" }}>
                                                <img
                                                  src={plane}
                                                  alt="Custom Plane"
                                                  width="68px"
                                                  style={{ marginTop: "20px" }}
                                                />
                                                <li className="ullist">
                                                  {getTime(
                                                    item?.same_flight_sub?.return_offer?.departure_time,
                                                    item?.same_flight_sub?.return_offer?.arrival_time
                                                  )}
                                                </li>
                                              </ul>
                                            </div>
                                            <div className="Dtimelist">
                                              <ul className="ul-list">
                                                <p className="ullist">
                                                  {item?.same_flight_sub?.return_offer?.arrival_date?.split("T")?.[0]}
                                                  <span className="depature_time">
                                                    {`${item?.same_flight_sub?.return_offer?.arrival_time?.split(":")?.[0]}:${item?.same_flight_sub?.return_offer?.arrival_time?.split(":")?.[1]}`}
                                                  </span>
                                                </p>
                                                <p className="depature_code">
                                                  {item?.same_flight_sub?.return_offer?.arrival_iata_code}
                                                </p>
                                              </ul>
                                            </div>
                                          </div>

                                          <div>
                                            <p className="number_stops">Number of stops : {item?.same_flight_sub?.outbound_offer?.number_of_stops}</p>
                                          </div>

                                        </div>
                                      </div>
                                      <div className="px-5">
                                        <ul className="row mt-2">
                                          {item?.same_flight_sub?.return_offer?.percentage_changes?.split(",")?.map((value, index) => {
                                            if (value !== "") {
                                              return (
                                                <li
                                                  className="col-md-12 hotel_desc_list"
                                                  key={index}
                                                >
                                                  {value}
                                                </li>
                                              );
                                            }
                                          })}
                                        </ul>
                                      </div>
                                    </div>


                                    <div className="col-md-3">
                                      {item?.same_flight_sub?.outbound_offer?.status == "PENDING" ? (
                                        <div className="text-center">
                                          <div>
                                            <p className="fstptag"> Total Price</p>
                                            <p className="fstptag"> {item?.same_flight_sub?.total_price}</p>
                                            {/* <p
                                               className="sndptag"
                                               style={{ whiteSpace: "nowrap" }}
                                             >
                                               You Can Save{" "}
                                               <span
                                                 style={{
                                                   color: "#61a108",
                                                   fontWeight: "bold",
                                                   fontSize: "13px",
                                                   whiteSpace: "nowrap",
                                                 }}
                                               >
                                                 {item?.same_flight_sub?.outbound_offer?.saved_amount}
 
                                               </span>
                                             </p> */}
                                          </div>
                                          <div className="ticket_detail_butns flex-column">
                                            <MDButton
                                              className="accept_butn"
                                              onClick={() =>
                                                handleStatus(item?.same_flight_sub?.outbound_offer?.id, "ACCEPT", item?.same_flight_sub?.outbound_offer?.airline_website)
                                              }
                                              disabled={
                                                ticketStatuses[item?.same_flight_sub?.outbound_offer?.id] ||
                                                item?.same_flight_sub?.outbound_offer?.status !== "PENDING"
                                              }
                                            >
                                              ACCEPT
                                            </MDButton>
                                            <MDButton
                                              className={`reject_butn my-1`}
                                              onClick={() =>
                                                handleStatus(item?.same_flight_sub?.outbound_offer?.id, "REJECT")
                                              }
                                              disabled={
                                                ticketStatuses[item?.same_flight_sub?.outbound_offer?.id] ||
                                                item?.same_flight_sub?.outbound_offer?.status !== "PENDING"
                                              }
                                            >
                                              REJECT
                                            </MDButton>
                                            {/* <MDButton
                                               className="eliminate_butn"
                                               onClick={() =>
                                                 handleStatus(
                                                   item?.same_flight_sub?.outbound_offer?.id,
                                                   "ELIMINATE"
                                                 )
                                               }
                                               disabled={
                                                 ticketStatuses[item?.same_flight_sub?.outbound_offer?.id] ||
                                                 item?.same_flight_sub?.outbound_offer?.status !== "PENDING"
                                               }
                                             >
                                               ELIMINATE
                                             </MDButton> */}
                                          </div>

                                        </div>
                                      ) : (
                                        <>
                                          <div className="d-flex justify-content-end  airlinetkt ">
                                            <div className="col-md-12 text-center">
                                              <div>
                                                <p className="fstptag"> Total Price</p>
                                                <p className="fstptag"> {item?.same_flight_sub?.total_price}</p>
                                                {/* <p
                                                   className="sndptag"
                                                   style={{ whiteSpace: "nowrap" }}
                                                 >
                                                   You Can Save{" "}
                                                   <span
                                                     style={{
                                                       color: "#61a108",
                                                       fontWeight: "bold",
                                                       fontSize: "13px",
                                                       whiteSpace: "nowrap",
                                                     }}
                                                   >
                                                     {item?.same_flight_sub?.outbound_offer?.saved_amount}
 
                                                   </span>
                                                 </p> */}
                                              </div>
                                              <div className="ticket_detail_butns flex-column"
                                              // style={{
                                              //   position: 'relative',
                                              //   right: '0',
                                              //   top: '67px'
                                              // }}
                                              >
                                                <MDButton
                                                  className={`accept_butn ${item?.same_flight_sub?.outbound_offer?.status == "ACCEPT" &&
                                                    "accepted_butn"
                                                    }`}
                                                  disabled={
                                                    item?.same_flight_sub?.outbound_offer?.status == "ACCEPT"
                                                  }
                                                  onClick={() =>
                                                    handleStatus(
                                                      item?.same_flight_sub?.outbound_offer?.id,
                                                      "ACCEPT"
                                                    )
                                                  }
                                                >
                                                  {item?.same_flight_sub?.outbound_offer?.status == "ACCEPT"
                                                    ? "ACCEPTED"
                                                    : "ACCEPT"}
                                                </MDButton>
                                                <MDButton
                                                  className={`my-1 ${item?.same_flight_sub?.outbound_offer?.status == "REJECT"
                                                    ? "rejected_butn"
                                                    : "reject_butn"
                                                    }`}
                                                  disabled={
                                                    item?.same_flight_sub?.outbound_offer?.status == "REJECT"
                                                  }
                                                  onClick={() =>
                                                    handleStatus(
                                                      item?.same_flight_sub?.outbound_offer?.id,
                                                      "REJECT"
                                                    )
                                                  }
                                                >
                                                  {item?.same_flight_sub?.outbound_offer?.status == "REJECT"
                                                    ? "REJECTED"
                                                    : "REJECT"}
                                                </MDButton>

                                                {/* <MDButton
                                                   className={`${item?.same_flight_sub?.outbound_offer?.status == "ELIMINATE"
                                                     ? "eliminated_butn"
                                                     : "eliminate_butn"
                                                     }`}
                                                   disabled={
                                                     item?.same_flight_sub?.outbound_offer?.status == "ELIMINATE"
                                                   }
                                                   onClick={() =>
                                                     handleStatus(
                                                       item?.same_flight_sub?.outbound_offer?.id,
                                                       "ELIMINATE"
                                                     )
                                                   }
                                                 >
                                                   {item?.same_flight_sub?.outbound_offer?.status == "ELIMINATE"
                                                     ? "ELIMINATED"
                                                     : "ELIMINATE"}
                                                 </MDButton> */}
                                              </div>
                                            </div>
                                          </div>
                                        </>
                                      )}
                                    </div>
                                  </div>
                                </>
                                :
                                <>
                                  <div
                                    className="row align-items-center"
                                    style={{ alignItems: "center" }}
                                  >
                                    <div className={`col-md-4
                                       d-flex airlinetkt align-items-center`}
                                    >
                                      <div
                                        className="col-md-8 d-flex"
                                        style={{ alignItems: "center" }}
                                      >
                                        <img
                                          src={airlinrlogo}
                                          alt=""
                                          className="img-fluid"
                                        />
                                        <div className="ticktnam mx-2">
                                          <p className="ticktptag12">
                                            {item?.same_flight_sub?.outbound_offer?.airline_name}
                                          </p>
                                          <p className="flight_class_name">
                                            {item?.flight_class ? item?.flight_class : ""}
                                          </p>
                                          <p className="flight_class_name">
                                            {item?.same_flight_sub?.outbound_offer?.airline_code ? `${item?.same_flight_sub?.outbound_offer?.airline_code}${item?.same_flight_sub?.outbound_offer?.flight_number}` : ""}
                                          </p>
                                          <p
                                            className="ticktptag21"
                                            style={{ fontSize: "13px", marginBottom: "0px !important" }}
                                          >
                                            <span
                                              style={{
                                                color: "#61a108",
                                                fontWeight: "bold",
                                                fontSize: "13px",
                                                whiteSpace: "nowrap",
                                              }}
                                            >
                                              {
                                                item?.same_flight_sub?.outbound_offer?.match_percentage ?
                                                  `${item?.same_flight_sub?.outbound_offer?.match_percentage}%` : ""
                                              }

                                            </span>
                                            {
                                              item?.same_flight_sub?.outbound_offer?.match_percentage ?
                                                "Matched" : ""
                                            }

                                          </p>
                                          {/* <p className="ticktptag21" style={{ fontSize: "11px" }}>
                                                 {ticket.outbound_offer.percentage_changes}%
                                               </p> */}
                                        </div>


                                      </div>
                                      <div
                                        className="col-md-4"
                                      >
                                        <p className="depature_code d-flex justify-content-center">    ${item?.same_flight_sub?.outbound_offer?.price}</p>

                                      </div>

                                    </div>

                                    <div className={`col-md-4`}>

                                      <div className="Dtime">
                                        <div className="Dtimelist">

                                          <ul className="ul-list">


                                            <p className="ullist">
                                              {
                                                item?.same_flight_sub?.outbound_offer?.departure_date?.split(
                                                  "T"
                                                )?.[0]
                                              }
                                              <span className="depature_time">
                                                {`${item?.same_flight_sub?.outbound_offer?.departure_time?.split(
                                                  ":"
                                                )?.[0]
                                                  }:${item?.same_flight_sub?.outbound_offer?.departure_time?.split(
                                                    ":"
                                                  )?.[1]
                                                  }`}
                                              </span>
                                            </p>

                                            <p className="depature_code">
                                              {item?.same_flight_sub?.outbound_offer?.departure_iata_code}
                                            </p>

                                          </ul>
                                        </div>
                                        <div className="Dtimelist">
                                          <ul style={{ padding: "0" }}>
                                            <img
                                              src={plane}
                                              alt="Custom Plane"
                                              width="68px"
                                              style={{ marginTop: "20px" }}
                                            />
                                            <li className="ullist">
                                              {getTime(
                                                item?.same_flight_sub?.outbound_offer?.departure_time,
                                                item?.same_flight_sub?.outbound_offer?.arrival_time
                                              )}
                                            </li>
                                          </ul>
                                        </div>
                                        <div className="Dtimelist">
                                          <ul className="ul-list">
                                            <p className="ullist">
                                              {
                                                item?.same_flight_sub?.outbound_offer?.arrival_date?.split(
                                                  "T"
                                                )?.[0]
                                              }
                                              <span className="depature_time">
                                                {`${item?.same_flight_sub?.outbound_offer?.arrival_time?.split(
                                                  ":"
                                                )?.[0]
                                                  }:${item?.same_flight_sub?.outbound_offer?.arrival_time?.split(
                                                    ":"
                                                  )?.[1]
                                                  }`}
                                              </span>
                                            </p>

                                            <p className="depature_code">
                                              {item?.same_flight_sub?.outbound_offer?.arrival_iata_code}
                                            </p>

                                          </ul>
                                        </div>
                                      </div>

                                      <div>
                                        <p className="number_stops">Number of stops : {item?.same_flight_sub?.outbound_offer?.number_of_stops}</p>
                                      </div>
                                    </div>


                                    {item?.same_flight_sub?.outbound_offer?.status == "PENDING" ? (
                                      <div className="col-md-4 d-flex airlinetkt">
                                        <div className="col-md-12">
                                          <div className="ticket_detail_butns">
                                            <MDButton
                                              className="accept_butn"
                                              onClick={() =>
                                                handleStatus(item?.same_flight_sub?.outbound_offer?.id, "ACCEPT", item?.same_flight_sub?.outbound_offer?.airline_website)
                                              }
                                              disabled={
                                                ticketStatuses[item?.same_flight_sub?.outbound_offer?.id] ||
                                                item?.same_flight_sub?.outbound_offer?.status !== "PENDING"
                                              }
                                            >
                                              ACCEPT
                                            </MDButton>
                                            <MDButton
                                              className={`reject_butn
                                             `}
                                              onClick={() =>
                                                handleStatus(item?.same_flight_sub?.outbound_offer?.id, "REJECT")
                                              }
                                              disabled={
                                                ticketStatuses[item?.same_flight_sub?.outbound_offer?.id] ||
                                                item?.same_flight_sub?.outbound_offer?.status !== "PENDING"
                                              }
                                            >
                                              REJECT
                                            </MDButton>
                                            {/* <MDButton
                                             className="eliminate_butn"
                                             onClick={() =>
                                               handleStatus(
                                                 item?.same_flight_sub?.outbound_offer?.id,
                                                 "ELIMINATE"
                                               )
                                             }
                                             disabled={
                                               ticketStatuses[item?.same_flight_sub?.outbound_offer?.id] ||
                                               item?.same_flight_sub?.outbound_offer?.status !== "PENDING"
                                             }
                                           >
                                             ELIMINATE
                                           </MDButton> */}
                                          </div>
                                        </div>
                                      </div>
                                    ) : (
                                      <>
                                        <div className="col-md-4 d-flex justify-content-end  airlinetkt ">
                                          <div className="col-md-12 text-end">
                                            <div className="ticket_detail_butns"
                                            // style={{
                                            //   position: 'relative',
                                            //   right: '0',
                                            //   top: '67px'
                                            // }}
                                            >
                                              {
                                                item?.same_flight_sub?.outbound_offer?.status == "ELIMINATE" ?
                                                  <MDButton
                                                    className={`accept_butn ${item?.same_flight_sub?.outbound_offer?.status == "ACCEPT" &&
                                                      "accepted_butn"
                                                      }`}
                                                    disabled={
                                                      item?.same_flight_sub?.outbound_offer?.status == "ACCEPT"
                                                    }
                                                    onClick={() =>
                                                      handleStatus(
                                                        item?.same_flight_sub?.outbound_offer?.id,
                                                        "REINSTATE"
                                                      )
                                                    }
                                                  >
                                                    {item?.same_flight_sub?.outbound_offer?.status == "ACCEPT"
                                                      ? "ACCEPTED"
                                                      : "ACCEPT"}
                                                  </MDButton>
                                                  :
                                                  <MDButton
                                                    className={`accept_butn ${item?.same_flight_sub?.outbound_offer?.status == "ACCEPT" &&
                                                      "accepted_butn"
                                                      }`}
                                                    disabled={
                                                      item?.same_flight_sub?.outbound_offer?.status == "ACCEPT"
                                                    }
                                                    onClick={() =>
                                                      handleStatus(
                                                        item?.same_flight_sub?.outbound_offer?.id,
                                                        "ACCEPT", item?.same_flight_sub?.outbound_offer?.airline_website
                                                      )
                                                    }
                                                  >
                                                    {item?.same_flight_sub?.outbound_offer?.status == "ACCEPT"
                                                      ? "ACCEPTED"
                                                      : "ACCEPT"}
                                                  </MDButton>

                                              }
                                              <MDButton
                                                className={`${item?.same_flight_sub?.outbound_offer?.status == "REJECT"
                                                  ? "rejected_butn"
                                                  : "reject_butn"
                                                  }`}
                                                disabled={
                                                  item?.same_flight_sub?.outbound_offer?.status == "REJECT"
                                                }
                                                onClick={() =>
                                                  handleStatus(
                                                    item?.same_flight_sub?.outbound_offer?.id,
                                                    "REJECT"
                                                  )
                                                }
                                              >
                                                {item?.same_flight_sub?.outbound_offer?.status == "REJECT"
                                                  ? "REJECTED"
                                                  : "REJECT"}
                                              </MDButton>

                                              {/* <MDButton
                                               className={`${item?.same_flight_sub?.outbound_offer?.status == "ELIMINATE"
                                                 ? "eliminated_butn"
                                                 : "eliminate_butn"
                                                 }`}
                                               disabled={
                                                 item?.same_flight_sub?.outbound_offer?.status == "ELIMINATE"
                                               }
                                               onClick={() =>
                                                 handleStatus(
                                                   item?.same_flight_sub?.outbound_offer?.id,
                                                   "ELIMINATE"
                                                 )
                                               }
                                             >
                                               {item?.same_flight_sub?.outbound_offer?.status == "ELIMINATE"
                                                 ? "ELIMINATED"
                                                 : "ELIMINATE"}
                                             </MDButton> */}
                                            </div>
                                          </div>
                                        </div>
                                      </>
                                    )}
                                  </div>
                                  <div className="px-5">
                                    <ul className="row mt-2">
                                      {item?.same_flight_sub?.outbound_offer?.percentage_changes?.split(",")?.map((value, index) => {
                                        if (value !== "") {
                                          return (
                                            <li
                                              className="col-md-12 hotel_desc_list"
                                              key={index}
                                            >
                                              {value}
                                            </li>
                                          );
                                        }
                                      })}
                                    </ul>
                                  </div>
                                </>
                            }
                          </div>

                        }

                        {item?.substitutes &&
                          item?.substitutes?.length > 0 &&
                          item?.substitutes
                            ?.slice(0, 2)
                            ?.map((ticket, index) => (
                              <div
                                key={index}
                                className="container-fluid p-3 mb-3 subtiktmain"
                              >
                                {
                                  ticket?.return_offer ?
                                    <>
                                      <div className="row align-items-center">
                                        <div className="col-md-9">
                                          <div
                                            className="row align-items-center"
                                            style={{ alignItems: "center" }}
                                          >
                                            <div className={`col-md-6 d-flex airlinetkt align-items-center`}
                                            >
                                              <div className="row align-items-center"
                                                style={{
                                                  width: "100%",
                                                  marginRight: "0px",
                                                }}>

                                                <div
                                                  className="col-md-8 d-flex"
                                                  style={{ alignItems: "center" }}
                                                >
                                                  <img
                                                    src={airlinrlogo}
                                                    alt=""
                                                    className="img-fluid"
                                                  />
                                                  <div className="ticktnam mx-2">
                                                    <p className="ticktptag12">
                                                      {ticket.outbound_offer?.airline_name}
                                                    </p>
                                                    <p className="flight_class_name">
                                                      {item?.flight_class}
                                                    </p>
                                                    <p className="flight_class_name">
                                                      {`${ticket?.outbound_offer?.airline_code}${ticket?.outbound_offer?.flight_number}`}
                                                    </p>
                                                    <p
                                                      className="ticktptag21"
                                                      style={{ fontSize: "13px", marginBottom: "0px !important" }}
                                                    >
                                                      <span
                                                        style={{
                                                          color: "#61a108",
                                                          fontWeight: "bold",
                                                          fontSize: "13px",
                                                          whiteSpace: "nowrap",
                                                        }}
                                                      >
                                                        {ticket.outbound_offer.match_percentage}%
                                                      </span>
                                                      &nbsp;Matched
                                                    </p>
                                                    {/* <p className="ticktptag21" style={{ fontSize: "11px" }}>
                                                      {ticket.outbound_offer.percentage_changes}%
                                                    </p> */}
                                                  </div>


                                                </div>
                                                <div
                                                  className="col-md-4"
                                                >
                                                  <p className="depature-heading">Depature</p>
                                                  <p className="depature_code d-flex justify-content-center">    ${ticket.outbound_offer?.price}</p>
                                                  <p
                                                    className="sndptag mb-0"
                                                    style={{ whiteSpace: "nowrap", marginBottom: "0px !important" }}
                                                  >
                                                    You Can Save{" "}
                                                    <span
                                                      style={{
                                                        color: "#61a108",
                                                        fontWeight: "bold",
                                                        fontSize: "13px",
                                                        whiteSpace: "nowrap",
                                                      }}
                                                    >
                                                      {ticket?.outbound_offer?.saved_amount}

                                                    </span>
                                                  </p>

                                                </div>
                                              </div>
                                            </div>
                                            <div className={`col-md-6 `}>

                                              <div className="Dtime">
                                                <div className="Dtimelist">
                                                  <ul className="ul-list">
                                                    <p className="ullist">
                                                      {
                                                        ticket?.outbound_offer?.departure_time?.split(
                                                          " "
                                                        )?.[0]
                                                      }
                                                      <span className="depature_time">
                                                        {`${ticket?.outbound_offer?.departure_time?.split(
                                                          " "
                                                        )?.[1]
                                                          } ${ticket?.outbound_offer?.departure_time?.split(
                                                            " "
                                                          )?.[2]
                                                          }`}
                                                      </span>
                                                    </p>

                                                    <p className="depature_code">
                                                      {ticket.outbound_offer?.departure_iata_code}
                                                    </p>

                                                  </ul>
                                                </div>
                                                <div className="Dtimelist">
                                                  <ul style={{ padding: "0" }}>
                                                    <img
                                                      src={plane}
                                                      alt="Custom Plane"
                                                      width="68px"
                                                      style={{ marginTop: "20px" }}
                                                    />
                                                    <li className="ullist">
                                                      {getTime(
                                                        ticket?.outbound_offer?.departure_time,
                                                        ticket?.outbound_offer?.arrival_time
                                                      )}
                                                    </li>
                                                  </ul>
                                                </div>
                                                <div className="Dtimelist">
                                                  <ul className="ul-list">
                                                    <p className="ullist">
                                                      {
                                                        ticket?.outbound_offer?.arrival_time?.split(
                                                          " "
                                                        )?.[0]
                                                      }
                                                      <span className="depature_time">
                                                        {`${ticket?.outbound_offer?.arrival_time?.split(
                                                          " "
                                                        )?.[1]
                                                          } ${ticket?.outbound_offer?.arrival_time?.split(
                                                            " "
                                                          )?.[2]
                                                          }`}
                                                      </span>
                                                    </p>

                                                    <p className="depature_code">
                                                      {ticket.outbound_offer?.arrival_iata_code}
                                                    </p>

                                                  </ul>
                                                </div>
                                              </div>

                                              <div>
                                                <p className="number_stops">Number of stops : {ticket?.return_offer?.number_of_stops}</p>
                                              </div>
                                            </div>

                                          </div>
                                          <div className="px-5">
                                            <ul className="row mt-2">
                                              {ticket?.outbound_offer?.percentage_changes?.split(",")?.map((value) => {
                                                if (value !== "") {
                                                  return (
                                                    <li
                                                      className="col-md-12 hotel_desc_list"
                                                      key={index}
                                                    >
                                                      {value}
                                                    </li>
                                                  );
                                                }
                                              })}
                                            </ul>
                                          </div>
                                          <div
                                            className="row align-items-center"
                                            style={{ alignItems: "center" }}
                                          >
                                            <div className={`col-md-6 d-flex airlinetkt align-items-center`}
                                            >
                                              <div className="row d-flex align-items-center"
                                                style={{
                                                  width: "100%",
                                                  marginRight: "0px",
                                                }}>
                                                <div
                                                  className="col-md-8 d-flex"
                                                  style={{ alignItems: "center" }}
                                                >
                                                  <img
                                                    src={airlinrlogo}
                                                    alt=""
                                                    className="img-fluid"
                                                  />
                                                  <div className="ticktnam mx-2">
                                                    <p className="ticktptag12">
                                                      {ticket?.return_offer?.airline_name}
                                                    </p>
                                                    <p className="flight_class_name">
                                                      {item?.flight_class}
                                                    </p>
                                                    <p className="flight_class_name">
                                                      {`${ticket?.return_offer?.airline_code}${ticket?.return_offer?.flight_number}`}
                                                    </p>
                                                    <p
                                                      className="ticktptag21"
                                                      style={{ fontSize: "13px", marginBottom: "0px !important" }}
                                                    >
                                                      <span
                                                        style={{
                                                          color: "#61a108",
                                                          fontWeight: "bold",
                                                          fontSize: "13px",
                                                          whiteSpace: "nowrap",
                                                        }}
                                                      >
                                                        {ticket?.return_offer?.match_percentage}%
                                                      </span>
                                                      &nbsp;Matched
                                                    </p>
                                                    {/* <p className="ticktptag21" style={{ fontSize: "11px" }}>
                                                      {ticket.return_offer?.percentage_changes}%
                                                    </p> */}
                                                  </div>


                                                </div>
                                                <div
                                                  className="col-md-4 text-end"
                                                >
                                                  <p className="depature-heading">Return</p>
                                                  <p className="depature_code d-flex justify-content-center"> {`${ticket?.return_offer && `$${ticket.return_offer?.price}`}`}</p>
                                                  <p
                                                    className="sndptag mb-0"
                                                    style={{ whiteSpace: "nowrap", marginBottom: "0px !important" }}
                                                  >
                                                    You Can Save{" "}
                                                    <span
                                                      style={{
                                                        color: "#61a108",
                                                        fontWeight: "bold",
                                                        fontSize: "13px",
                                                        whiteSpace: "nowrap",
                                                      }}
                                                    >
                                                      {ticket?.return_offer?.saved_amount}

                                                    </span>
                                                  </p>
                                                </div>
                                              </div>
                                            </div>
                                            <div className={`col-md-6 `}>
                                              <div className="Dtime">
                                                <div className="Dtimelist">
                                                  <ul className="ul-list">
                                                    <p className="ullist">
                                                      {ticket?.return_offer?.departure_time?.split(" ")?.[0]}
                                                      <span className="depature_time">
                                                        {`${ticket?.return_offer?.departure_time?.split(" ")?.[1]} ${ticket?.return_offer?.departure_time?.split(" ")?.[2]}`}
                                                      </span>
                                                    </p>
                                                    <p className="depature_code">
                                                      {ticket.return_offer?.departure_iata_code}
                                                    </p>
                                                  </ul>
                                                </div>
                                                <div className="Dtimelist">
                                                  <ul style={{ padding: "0" }}>
                                                    <img
                                                      src={plane}
                                                      alt="Custom Plane"
                                                      width="68px"
                                                      style={{ marginTop: "20px" }}
                                                    />
                                                    <li className="ullist">
                                                      {getTime(
                                                        ticket?.return_offer?.departure_time,
                                                        ticket?.return_offer?.arrival_time
                                                      )}
                                                    </li>
                                                  </ul>
                                                </div>
                                                <div className="Dtimelist">
                                                  <ul className="ul-list">
                                                    <p className="ullist">
                                                      {ticket?.return_offer?.arrival_time?.split(" ")?.[0]}
                                                      <span className="depature_time">
                                                        {`${ticket?.return_offer?.arrival_time?.split(" ")?.[1]} ${ticket?.return_offer?.arrival_time?.split(" ")?.[2]}`}
                                                      </span>
                                                    </p>
                                                    <p className="depature_code">
                                                      {ticket.return_offer?.arrival_iata_code}
                                                    </p>
                                                  </ul>
                                                </div>
                                              </div>

                                              <div>
                                                <p className="number_stops">Number of stops : {ticket?.return_offer?.number_of_stops}</p>
                                              </div>

                                            </div>
                                          </div>
                                          <div className="px-5">
                                            <ul className="row mt-2">
                                              {ticket?.return_offer?.percentage_changes?.split(",")?.map((value) => {
                                                if (value !== "") {
                                                  return (
                                                    <li
                                                      className="col-md-12 hotel_desc_list"
                                                      key={index}
                                                    >
                                                      {value}
                                                    </li>
                                                  );
                                                }
                                              })}
                                            </ul>
                                          </div>
                                        </div>


                                        <div className="col-md-3">
                                          {ticket?.outbound_offer?.status == "PENDING" ? (
                                            <div className="text-center">
                                              <div>
                                                <p className="fstptag"> Total Price</p>
                                                <p className="fstptag"> {ticket?.total_price}</p>
                                                {/* <p
                                                  className="sndptag"
                                                  style={{ whiteSpace: "nowrap" }}
                                                >
                                                  You Can Save{" "}
                                                  <span
                                                    style={{
                                                      color: "#61a108",
                                                      fontWeight: "bold",
                                                      fontSize: "13px",
                                                      whiteSpace: "nowrap",
                                                    }}
                                                  >
                                                    {ticket?.outbound_offer?.saved_amount}

                                                  </span>
                                                </p> */}
                                              </div>
                                              <div className="ticket_detail_butns flex-column">
                                                <MDButton
                                                  className="accept_butn"
                                                  onClick={() =>
                                                    handleStatus(ticket?.outbound_offer?.id, "ACCEPT", ticket?.outbound_offer?.airline_website)
                                                  }
                                                  disabled={
                                                    ticketStatuses[ticket?.outbound_offer?.id] ||
                                                    ticket?.outbound_offer?.status !== "PENDING"
                                                  }
                                                >
                                                  ACCEPT
                                                </MDButton>
                                                <MDButton
                                                  className={`reject_butn my-1`}
                                                  onClick={() =>
                                                    handleStatus(ticket?.outbound_offer?.id, "REJECT")
                                                  }
                                                  disabled={
                                                    ticketStatuses[ticket?.outbound_offer?.id] ||
                                                    ticket?.outbound_offer?.status !== "PENDING"
                                                  }
                                                >
                                                  REJECT
                                                </MDButton>
                                                <MDButton
                                                  className="eliminate_butn"
                                                  onClick={() =>
                                                    handleStatus(
                                                      ticket?.outbound_offer?.id,
                                                      "ELIMINATE"
                                                    )
                                                  }
                                                  disabled={
                                                    ticketStatuses[ticket?.outbound_offer?.id] ||
                                                    ticket?.outbound_offer?.status !== "PENDING"
                                                  }
                                                >
                                                  ELIMINATE
                                                </MDButton>
                                              </div>

                                            </div>
                                          ) : (
                                            <>
                                              <div className="d-flex justify-content-end  airlinetkt ">
                                                <div className="col-md-12 text-center">
                                                  <div>
                                                    <p className="fstptag"> Total Price</p>
                                                    <p className="fstptag"> {ticket?.total_price}</p>
                                                    {/* <p
                                                      className="sndptag"
                                                      style={{ whiteSpace: "nowrap" }}
                                                    >
                                                      You Can Save{" "}
                                                      <span
                                                        style={{
                                                          color: "#61a108",
                                                          fontWeight: "bold",
                                                          fontSize: "13px",
                                                          whiteSpace: "nowrap",
                                                        }}
                                                      >
                                                        {ticket?.outbound_offer?.saved_amount}

                                                      </span>
                                                    </p> */}
                                                  </div>
                                                  <div className="ticket_detail_butns flex-column"
                                                  // style={{
                                                  //   position: 'relative',
                                                  //   right: '0',
                                                  //   top: '67px'
                                                  // }}
                                                  >
                                                    {
                                                      ticket?.outbound_offer?.status == "ELIMINATE" ?
                                                        <MDButton
                                                          className={`accept_butn ${ticket?.outbound_offer?.status == "ACCEPT" &&
                                                            "accepted_butn"
                                                            }`}
                                                          disabled={
                                                            ticket?.outbound_offer?.status == "ACCEPT"
                                                          }
                                                          onClick={() =>
                                                            handleStatus(
                                                              ticket?.outbound_offer?.id,
                                                              "REINSTATE"
                                                            )
                                                          }
                                                        >
                                                          {ticket?.outbound_offer?.status == "ACCEPT"
                                                            ? "ACCEPTED"
                                                            : "ACCEPT"}
                                                        </MDButton>
                                                        :
                                                        <MDButton
                                                          className={`accept_butn ${ticket?.outbound_offer?.status == "ACCEPT" &&
                                                            "accepted_butn"
                                                            }`}
                                                          disabled={
                                                            ticket?.outbound_offer?.status == "ACCEPT"
                                                          }
                                                          onClick={() =>
                                                            handleStatus(
                                                              ticket?.outbound_offer?.id,
                                                              "ACCEPT", ticket?.outbound_offer?.airline_website
                                                            )
                                                          }
                                                        >
                                                          {ticket?.outbound_offer?.status == "ACCEPT"
                                                            ? "ACCEPTED"
                                                            : "ACCEPT"}
                                                        </MDButton>

                                                    }
                                                    <MDButton
                                                      className={`my-1 ${ticket?.outbound_offer?.status == "REJECT"
                                                        ? "rejected_butn"
                                                        : "reject_butn"
                                                        }`}
                                                      disabled={
                                                        ticket?.outbound_offer?.status == "REJECT"
                                                      }
                                                      onClick={() =>
                                                        handleStatus(
                                                          ticket?.outbound_offer?.id,
                                                          "REJECT"
                                                        )
                                                      }
                                                    >
                                                      {ticket?.outbound_offer?.status == "REJECT"
                                                        ? "REJECTED"
                                                        : "REJECT"}
                                                    </MDButton>

                                                    <MDButton
                                                      className={`${ticket?.outbound_offer?.status == "ELIMINATE"
                                                        ? "eliminated_butn"
                                                        : "eliminate_butn"
                                                        }`}
                                                      disabled={
                                                        ticket?.outbound_offer?.status == "ELIMINATE"
                                                      }
                                                      onClick={() =>
                                                        handleStatus(
                                                          ticket?.outbound_offer?.id,
                                                          "ELIMINATE"
                                                        )
                                                      }
                                                    >
                                                      {ticket?.outbound_offer?.status == "ELIMINATE"
                                                        ? "ELIMINATED"
                                                        : "ELIMINATE"}
                                                    </MDButton>
                                                  </div>
                                                </div>
                                              </div>
                                            </>
                                          )}
                                        </div>
                                      </div>
                                    </>
                                    :
                                    <>
                                      <div
                                        className="row align-items-center"
                                        style={{ alignItems: "center" }}
                                      >
                                        <div className={`col-md-4
                                      d-flex airlinetkt align-items-center`}
                                        >
                                          <div
                                            className="col-md-8 d-flex"
                                            style={{ alignItems: "center" }}
                                          >
                                            <img
                                              src={airlinrlogo}
                                              alt=""
                                              className="img-fluid"
                                            />
                                            <div className="ticktnam mx-2">
                                              <p className="ticktptag12">
                                                {ticket?.outbound_offer?.airline_name}
                                              </p>
                                              <p className="flight_class_name">
                                                {item?.flight_class}
                                              </p>
                                              <p className="flight_class_name">
                                                {`${ticket?.outbound_offer?.airline_code}${ticket?.outbound_offer?.flight_number}`}
                                              </p>
                                              <p
                                                className="ticktptag21"
                                                style={{ fontSize: "13px", marginBottom: "0px !important" }}
                                              >
                                                <span
                                                  style={{
                                                    color: "#61a108",
                                                    fontWeight: "bold",
                                                    fontSize: "13px",
                                                    whiteSpace: "nowrap",
                                                  }}
                                                >
                                                  {ticket.outbound_offer.match_percentage}%
                                                </span>
                                                &nbsp;Matched
                                              </p>
                                              {/* <p className="ticktptag21" style={{ fontSize: "11px" }}>
                                                {ticket.outbound_offer.percentage_changes}%
                                              </p> */}
                                            </div>


                                          </div>
                                          <div
                                            className="col-md-4"
                                          >
                                            <p className="depature_code d-flex justify-content-center">    ${ticket.outbound_offer?.price}</p>

                                          </div>

                                        </div>

                                        <div className={`col-md-4`}>

                                          <div className="Dtime">
                                            <div className="Dtimelist">

                                              <ul className="ul-list">


                                                <p className="ullist">
                                                  {
                                                    ticket?.outbound_offer?.departure_time?.split(
                                                      " "
                                                    )?.[0]
                                                  }
                                                  <span className="depature_time">
                                                    {`${ticket?.outbound_offer?.departure_time?.split(
                                                      " "
                                                    )?.[1]
                                                      } ${ticket?.outbound_offer?.departure_time?.split(
                                                        " "
                                                      )?.[2]
                                                      }`}
                                                  </span>
                                                </p>

                                                <p className="depature_code">
                                                  {ticket.outbound_offer?.departure_iata_code}
                                                </p>

                                              </ul>
                                            </div>
                                            <div className="Dtimelist">
                                              <ul style={{ padding: "0" }}>
                                                <img
                                                  src={plane}
                                                  alt="Custom Plane"
                                                  width="68px"
                                                  style={{ marginTop: "20px" }}
                                                />
                                                <li className="ullist">
                                                  {getTime(
                                                    ticket?.outbound_offer?.departure_time,
                                                    ticket?.outbound_offer?.arrival_time
                                                  )}
                                                </li>
                                              </ul>
                                            </div>
                                            <div className="Dtimelist">
                                              <ul className="ul-list">
                                                <p className="ullist">
                                                  {
                                                    ticket?.outbound_offer?.arrival_time?.split(
                                                      " "
                                                    )?.[0]
                                                  }
                                                  <span className="depature_time">
                                                    {`${ticket?.outbound_offer?.arrival_time?.split(
                                                      " "
                                                    )?.[1]
                                                      } ${ticket?.outbound_offer?.arrival_time?.split(
                                                        " "
                                                      )?.[2]
                                                      }`}
                                                  </span>
                                                </p>

                                                <p className="depature_code">
                                                  {ticket.outbound_offer?.arrival_iata_code}
                                                </p>

                                              </ul>
                                            </div>
                                          </div>

                                          <div>
                                            <p className="number_stops">Number of stops : {ticket?.outbound_offer?.number_of_stops}</p>
                                          </div>

                                        </div>


                                        {ticket?.outbound_offer?.status == "PENDING" ? (
                                          <div className="col-md-4 d-flex airlinetkt">
                                            <div className="col-md-12">
                                              <div className="ticket_detail_butns">
                                                <MDButton
                                                  className="accept_butn"
                                                  onClick={() =>
                                                    handleStatus(ticket?.outbound_offer?.id, "ACCEPT", ticket?.outbound_offer?.airline_website)
                                                  }
                                                  disabled={
                                                    ticketStatuses[ticket?.outbound_offer?.id] ||
                                                    ticket?.outbound_offer?.status !== "PENDING"
                                                  }
                                                >
                                                  ACCEPT
                                                </MDButton>
                                                <MDButton
                                                  className={`reject_butn
                                            `}
                                                  onClick={() =>
                                                    handleStatus(ticket?.outbound_offer?.id, "REJECT")
                                                  }
                                                  disabled={
                                                    ticketStatuses[ticket?.outbound_offer?.id] ||
                                                    ticket?.outbound_offer?.status !== "PENDING"
                                                  }
                                                >
                                                  REJECT
                                                </MDButton>
                                                <MDButton
                                                  className="eliminate_butn"
                                                  onClick={() =>
                                                    handleStatus(
                                                      ticket?.outbound_offer?.id,
                                                      "ELIMINATE"
                                                    )
                                                  }
                                                  disabled={
                                                    ticketStatuses[ticket?.outbound_offer?.id] ||
                                                    ticket?.outbound_offer?.status !== "PENDING"
                                                  }
                                                >
                                                  ELIMINATE
                                                </MDButton>
                                              </div>
                                            </div>
                                          </div>
                                        ) : (
                                          <>
                                            <div className="col-md-4 d-flex justify-content-end  airlinetkt ">
                                              <div className="col-md-12 text-end">
                                                <div className="ticket_detail_butns"
                                                // style={{
                                                //   position: 'relative',
                                                //   right: '0',
                                                //   top: '67px'
                                                // }}
                                                >
                                                  {
                                                    ticket?.outbound_offer?.status == "ELIMINATE" ?
                                                      <MDButton
                                                        className={`accept_butn ${ticket?.outbound_offer?.status == "ACCEPT" &&
                                                          "accepted_butn"
                                                          }`}
                                                        disabled={
                                                          ticket?.outbound_offer?.status == "ACCEPT"
                                                        }
                                                        onClick={() =>
                                                          handleStatus(
                                                            ticket?.outbound_offer?.id,
                                                            "REINSTATE"
                                                          )
                                                        }
                                                      >
                                                        {ticket?.outbound_offer?.status == "ACCEPT"
                                                          ? "ACCEPTED"
                                                          : "ACCEPT"}
                                                      </MDButton>
                                                      :
                                                      <MDButton
                                                        className={`accept_butn ${ticket?.outbound_offer?.status == "ACCEPT" &&
                                                          "accepted_butn"
                                                          }`}
                                                        disabled={
                                                          ticket?.outbound_offer?.status == "ACCEPT"
                                                        }
                                                        onClick={() =>
                                                          handleStatus(
                                                            ticket?.outbound_offer?.id,
                                                            "ACCEPT", ticket?.outbound_offer?.airline_website
                                                          )
                                                        }
                                                      >
                                                        {ticket?.outbound_offer?.status == "ACCEPT"
                                                          ? "ACCEPTED"
                                                          : "ACCEPT"}
                                                      </MDButton>

                                                  }
                                                  <MDButton
                                                    className={`${ticket?.outbound_offer?.status == "REJECT"
                                                      ? "rejected_butn"
                                                      : "reject_butn"
                                                      }`}
                                                    disabled={
                                                      ticket?.outbound_offer?.status == "REJECT"
                                                    }
                                                    onClick={() =>
                                                      handleStatus(
                                                        ticket?.outbound_offer?.id,
                                                        "REJECT"
                                                      )
                                                    }
                                                  >
                                                    {ticket?.outbound_offer?.status == "REJECT"
                                                      ? "REJECTED"
                                                      : "REJECT"}
                                                  </MDButton>

                                                  <MDButton
                                                    className={`${ticket?.outbound_offer?.status == "ELIMINATE"
                                                      ? "eliminated_butn"
                                                      : "eliminate_butn"
                                                      }`}
                                                    disabled={
                                                      ticket?.outbound_offer?.status == "ELIMINATE"
                                                    }
                                                    onClick={() =>
                                                      handleStatus(
                                                        ticket?.outbound_offer?.id,
                                                        "ELIMINATE"
                                                      )
                                                    }
                                                  >
                                                    {ticket?.outbound_offer?.status == "ELIMINATE"
                                                      ? "ELIMINATED"
                                                      : "ELIMINATE"}
                                                  </MDButton>
                                                </div>
                                              </div>
                                            </div>
                                          </>
                                        )}
                                      </div>
                                      <div className="px-5">
                                        <ul className="row mt-2">
                                          {ticket?.outbound_offer?.percentage_changes?.split(",")?.map((value) => {
                                            if (value !== "") {
                                              return (
                                                <li
                                                  className="col-md-12 hotel_desc_list"
                                                  key={index}
                                                >
                                                  {value}
                                                </li>
                                              );
                                            }
                                          })}
                                        </ul>
                                      </div>
                                    </>
                                }


                                {/* Percentage Changes */}
                                <div>
                                  <ul className="row mt-2">
                                    {ticket.percentage_changes
                                      ?.split(",")
                                      .map((value, idx) => {
                                        if (value !== "") {
                                          return (
                                            <li
                                              className="col-md-12 hotel_desc_list"
                                              key={idx}
                                            >
                                              {value}
                                            </li>
                                          );
                                        }
                                        return null;
                                      })}
                                  </ul>
                                </div>
                              </div>
                            ))}
                      </>
                    )}
                  </div>
                </AccordionDetails>
              </Accordion>
            ))

            :
            <>
              <div className="text-center py-3">
                <MDTypography variant="h4">You don't have any Bookings at the moment!                </MDTypography>
              </div></>}
        </div>
      )}

      <Modal show={show} onHide={handleClose} centered>
        {/* <Modal.Header closeButton>
        </Modal.Header> */}
        <Modal.Body>
          <div className="text-center p-4">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="40"
              height="40"
              fill="currentColor"
              className="bi bi-check-circle-fill status_icon"
              viewBox="0 0 16 16"
            >
              <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0m-3.97-3.03a.75.75 0 0 0-1.08.022L7.477 9.417 5.384 7.323a.75.75 0 0 0-1.06 1.06L6.97 11.03a.75.75 0 0 0 1.079-.02l3.992-4.99a.75.75 0 0 0-.01-1.05z" />
            </svg>
            {status == "ACCEPT" && (
              <MDTypography variant="p" className="delete_msj">
                We are glad to know, you would like to Accept this Offer!
              </MDTypography>
            )}

            {status == "REJECT" && (
              <MDTypography variant="p" className="delete_msj">
                Are you sure? You would like to Reject this offer.
              </MDTypography>
            )}

            {status == "ELIMINATE" && (
              <MDTypography variant="p" className="delete_msj">
                Are you sure? You would like to Eliminate this offer.
              </MDTypography>
            )}
            {
              status == "REINSTATE" && (
                <MDTypography variant="p" className="delete_msj">
                  Are you sure? Would you like to reinstate this flight?
                </MDTypography>
              )
            }

            {status !== "ACCEPT" && status !== "REINSTATE" && (
              <div className="my-3 text-left">
                <FormControl fullWidth>
                  <InputLabel id="reason-select-label">
                    Select Reason
                  </InputLabel>
                  <Select
                    labelId="reason-select-label"
                    value={selectedReason}
                    onChange={handleChange}
                    label="Select Reason"
                    className="select_reason_input"
                  >
                    {REASON_CHOICES?.map((reason) => (
                      <MenuItem key={reason[0]} value={reason[0]}>
                        {reason[1]}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
                {selectedReason === "OTHER" && (
                  <TextField
                    fullWidth
                    label="Please specify the reason"
                    variant="outlined"
                    value={otherReasonText}
                    onChange={(e) => {
                      setOtherReasonText(e.target.value);
                      setSelectMesage("");
                    }}
                    className="other_reason_input mt-3"
                    multiline
                    rows={3}
                  />
                )}
                <div className="error_message my-1">{selectMessage}</div>
              </div>
            )}

            <Button
              variant="secondary"
              className="cancel_butn"
              onClick={handleClose}
            >
              {status == "ACCEPT" ? "No" : "Cancel"}
            </Button>
            {
              status == "ACCEPT" ?
                <Button
                  variant="primary"
                  className="delete_butn"
                  onClick={() => handleAcceptSite()}
                >
                  Yes
                </Button>
                :
                <Button
                  variant="primary"
                  className="delete_butn"
                  onClick={() => {
                    handleUpdateStatus();
                  }}
                > Update
                </Button>
            }
          </div>
        </Modal.Body>
      </Modal>
    </MDBox>
  );
}

export default BookingCard;