import React from "react";
import "../Front-Page/aboutus.css";
import featureicon1 from "assets/img/featureicon1.png";
import featureicon3 from "assets/img/featureicon3.png";
import featureicon2 from "assets/img/featureicon2.png";
import MDTypography from "components/MDTypography";

const AboutUs = () => {
  return (
    <div className="mainabout-us-container">
      <div className="about-us-container">
        <div
          className=""
          style={{ marginTop: "40px" }}
        >
          <p className="about-heading">More About AiRebooker</p>
          <h1 className="highlight">About</h1>
          <div className="abutsec">
            <h1
              style={{ fontSize: "65px",color: "#3D4B7C" }}
            >
              Us
            </h1>
            <div className="about_descrition_section">
              <p className="about-description">
                At AiRebooker, we are dedicated to simplifying the travel
                experience for frequent travelers. Our innovative mobile app
                helps users automatically monitor travel reservations, track
                price changes, and secure savings through rebooking at lower
                rates. By leveraging advanced technology and seamless
                integration with major travel service providers, AiRebooker
                empowers users to manage their travel plans effortlessly.
              </p>
            </div>
          </div>
        </div>
        <div className="container about_feature_section">
          <div className="row">
            <div className="col-12 col-md-4 mb-4">
              <div className="feature">
                <img
                  src={featureicon1}
                  alt="Feature Icon 1"
                  className="img-fluid"
                />
                <div className="aboutfeature">
                  <MDTypography varient="p">
                    Automates price monitoring for flights, hotels, and car
                    rentals.
                  </MDTypography>
                </div>
              </div>
            </div>
            <div className="col-12 col-md-4 mb-4">
              <div className="feature">
                <img
                  src={featureicon2}
                  alt="Feature Icon 2"
                  className="img-fluid"
                />
                <div className="aboutfeature">
                  <MDTypography varient="p">
                    Offers easy rebooking options to help users save money on
                    their travel bookings.
                  </MDTypography>
                </div>
              </div>
            </div>
            <div className="col-12 col-md-4 mb-4">
              <div className="feature">
                <img
                  src={featureicon3}
                  alt="Feature Icon 3"
                  className="img-fluid"
                />
                <div className="aboutfeature">
                  <MDTypography varient="p">
                    Provides a centralized hub for managing all travel
                    reservations in one place.
                  </MDTypography>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AboutUs;
