import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import Button from "@mui/material/Button";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { deleteAdminData } from "utils/api";
import Loader from "components/Loader";
import MUIDataTable from "mui-datatables";
import Icon from "@mui/material/Icon";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { subscribedUsers } from "utils/api";
import { planDescription } from "utils/api";
import { planAmountChange } from "utils/api";


const ITEMS_PER_PAGE = 5;

function PaymentDetails() {
    const navigate = useNavigate();
    const [users, setUsers] = useState([]);
    const [loading, setLoading] = useState(false);
    const [amountDetail, setAmountDetail] = useState("")
    const [amount, setAmount] = useState("")


    const fetchUsers = async () => {
        try {
            setLoading(true);
            const response = await subscribedUsers();
            if (response.status == 200) {
                setLoading(false);
                setUsers(response.data);
            } else {
                setLoading(false);
                toast.error("Failed to fetch users");
                setUsers([]);
            }
        } catch (error) {
            setUsers([]);
            console.error("Error fetching users:", error);
            setLoading(false);
        }
    };

    const fetchAmount = () => {
        planDescription().then((res) => {
            if (res.status == 200) {
                setAmount(res.data?.[0]?.price)
                setAmountDetail(res?.data?.[0])
            }
        }).catch((error) => {
            console.log(error)
        })
    }

    useEffect(() => {
        fetchUsers();
        fetchAmount()
    }, []);


    const getMuiTheme = () =>
        createTheme({
            components: {
                MuiTableCell: {
                    styleOverrides: {
                        root: {
                            textAlign: "left",
                        },
                        head: {
                            fontWeight: "bold",
                            textAlign: "left",
                        },
                    },
                },
            },
        });

    const columns = [
        {
            name: "sr_no",
            label: "Sr. No.",
            options: {
                customBodyRender: (value, tableMeta) => {
                    return tableMeta.rowIndex + 1;
                },
            },
        },
        {
            name: "first_name",
            label: "First Name",
        },
        {
            name: "last_name",
            label: "Last Name",
        },
        {
            name: "email",
            label: "Email",
        },
        {
            name: "amount",
            label: "Amount",
        },
        {
            name: "currency",
            label: "Currency",
        },
        {
            name: "payment_status",
            label: "Status",
        },
        {
            name: "payment_date",
            label: "Payment Date",
        },
    ];

    const options = {
        selectableRows: "none",
        responsive: "stacked",
        print: false,
        download: false,
        viewColumns: false,
    };

    // Transform user data to include payments
    const transformedData = users?.flatMap(user =>
        user.payments.map(payment => ({
            first_name: user.first_name,
            last_name: user.last_name,
            email: user.email,
            amount: payment.amount,
            currency: payment.currency,
            payment_status: payment.payment_status,
            payment_date: new Date(payment.payment_date).toLocaleString(), // Format the date
        }))
    );

    const handlePrice = (e) => {
        setAmount(e.target.value)
    }

    const updateAmount = () => {
        const id = amountDetail?.id
        planAmountChange(id, { price: amount }).then((res) => {
            if (res.status == 200) {
                toast.success("Successfully Updated Registration Amount", {
                    theme:"colored"
                })
                setAmount(res.data?.[0]?.price)
                setAmountDetail(res?.data?.[0])
            }
        }).catch((error) => {
            console.log(error)
        })
    }

    return (
        <DashboardLayout>
            <DashboardNavbar />
            <MDBox pt={6} pb={3}>
                <Grid container spacing={6}>
                    <Grid item xs={12}>
                        <Card>
                            <MDBox
                                mx={2}
                                mt={-3}
                                py={3}
                                px={2}
                                variant="gradient"
                                bgColor="info"
                                borderRadius="lg"
                                coloredShadow="info"
                                display="flex"
                                justifyContent="space-between"
                            >
                                <MDTypography variant="h6" color="white">
                                    Registration Payment Details
                                </MDTypography>
                                <div className="d-flex align-items-center">
                                    <p className="registraion_text">Registration Amount:</p>
                                    <input type="number" className="price_input" value={amount} name="amount"
                                        onChange={(e) => { handlePrice(e) }} />
                                    <svg xmlns="http://www.w3.org/2000/svg" onClick={()=> updateAmount()} width="32" height="32" fill="currentColor" className="bi bi-check-square-fill amount_check_box" viewBox="0 0 16 16">
                                        <path d="M2 0a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2zm10.03 4.97a.75.75 0 0 1 .011 1.05l-3.992 4.99a.75.75 0 0 1-1.08.02L4.324 8.384a.75.75 0 1 1 1.06-1.06l2.094 2.093 3.473-4.425a.75.75 0 0 1 1.08-.022z" />
                                    </svg>
                                </div>
                            </MDBox>
                            {loading ? (
                                <Loader />
                            ) : (
                                <MDBox pt={3}>
                                    <div className="table-container">
                                        <div className="custom-table">
                                            {transformedData?.length > 0 && (
                                                <ThemeProvider theme={getMuiTheme()}>
                                                    <MUIDataTable
                                                        data={transformedData}
                                                        columns={columns}
                                                        options={options}
                                                    />
                                                </ThemeProvider>
                                            )}
                                        </div>
                                    </div>
                                </MDBox>
                            )}
                        </Card>
                    </Grid>
                </Grid>
            </MDBox>

        </DashboardLayout>
    );
}

export default PaymentDetails;
