import { useState } from "react";

// react-router-dom components
import { Link, NavLink, useNavigate } from "react-router-dom";
import Visibility from "@mui/icons-material/Visibility"; // Eye open
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import MDTypography from "components/MDTypography";
import MDButton from "components/MDButton";
import { IconButton } from "@mui/material";
import BasicLayout from "layouts/authentication/components/BasicLayout";

import Logo from "assets/images/auth/logo.png";
import { loginUser } from "utils/api";
import MDAlert from "components/MDAlert";

function Login() {
  const navigate = useNavigate();
  const [showAlert, setShowAlert] = useState(false);
  const [errorAlert, setErrorAlert] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [formdata, setFormdata] = useState({
    email: "",
    password: "",
  });


  const handleToggle = () => {
    setShowPassword(!showPassword);
  };

  const handleFormdata = (e) => {
    const { name, value } = e.target;
    setFormdata({ ...formdata, [name]: value });
  };

  const handleSignIn = (e) => {
    e.preventDefault()
    if (formdata?.email && formdata?.password) {
      loginUser(formdata).then((res) => {
        if (res.status === 200) {
          if (!res?.data?.user?.is_subscription && res?.data?.user?.user_type === 2) {
            navigate("/subscription")
          } else {
            navigate("/dashboard");
          }
          localStorage.setItem("user_access_token", res.data.access);
          localStorage.setItem("active-user", JSON.stringify(res.data.user));
          setShowAlert("Successfully Login");
          setTimeout(() => {
            setShowAlert(false)
          }, 3000)
        }
        else {
          setErrorAlert(res.data.error);
          setTimeout(() => {
            setShowAlert(false)
          }, 3000)
        }
      }).catch((error) => {
        console.log(error)
      })
    }
  };

  const alertContent = () => (
    <MDTypography variant="body2" color="white">
      <MDTypography
        component="a"
        href="#"
        variant="body2"
        fontWeight="medium"
        color="white"
      >
        {showAlert}
        {errorAlert}
      </MDTypography>
    </MDTypography>
  );

  return (
    <BasicLayout>
      <div style={{ position: "absolute", right: "25px", top: "20px" }}>
        {showAlert && (
          <MDAlert color="info" dismissible>
            {alertContent("info")}
          </MDAlert>
        )}
        {errorAlert && (
          <MDAlert color="error" dismissible>
            {alertContent("error")}
          </MDAlert>
        )}
      </div>
      <div className="main_file">
        <div className="bg_login1">
          <div className="bg_image_login">
            <img src={Logo} />
          </div>
        </div>
        <div className="login_form login_form_inner">
          <div>
            <MDTypography
              variant="h3"
              fontWeight="medium"
              color="#3D4B7C"
              mt={1}
            >
              Let’s Sign In 👇
            </MDTypography>
            <MDTypography display="block" variant="button" color="blue" my={1}>
              Hey, Enter your details to get sign in to your account.
            </MDTypography>
            <div>
              <input
                type="email"
                placeholder="Email"
                name="email"
                value={formdata.email}
                onChange={handleFormdata}
                className="input_design"
              />
              <input
                type={showPassword ? "text" : "password"}
                placeholder="Password"
                name="password"
                value={formdata.password}
                onChange={handleFormdata}
                className="input_design"
              />
              <IconButton
                size="small"
                disableRipple
                onClick={handleToggle}
                style={{ marginLeft: "-40px" }}
              >
                {!showPassword ? <VisibilityOff /> : <Visibility />}
              </IconButton>
            </div>
            <div className="forgot_div">
              <NavLink to="/reset-password" className="forgot_link">
                Forgot password?
              </NavLink>
            </div>
            <div className="sign_butn">
              <MDButton
                variant="gradient"
                onClick={(e) => handleSignIn(e)}
                color="info"
                fullWidth
              >
                Sign in
              </MDButton>
            </div>
            <div>
              <MDTypography
                display="block"
                variant="button"
                color="blue"
                my={1}
              >
                Don't have an account? <Link to="/register">Register</Link>
              </MDTypography>
            </div>
          </div>
        </div>
      </div>
    </BasicLayout>
  );
}

export default Login;
