
import Dashboard from "layouts/dashboard";
import Tables from "layouts/tables";
import Billing from "layouts/billing";
import Profile from "layouts/profile";
import Icon from "@mui/material/Icon";
import PaymentDetails from "layouts/PaymentDetail";

const routes = [
  {
    type: "collapse",
    name: "Dashboard",
    key: "dashboard",
    icon: <Icon fontSize="small" className="sidebar_icons">dashboard</Icon>,
    route: "/dashboard",
    component: <Dashboard />,
  },
  {
    type: "collapse",
    name: "User Management",
    key: "tables",
    icon: <Icon fontSize="small" className="sidebar_icons">table_view</Icon>,
    route: "/user-management",
    component: <Tables />,
  },
  // {
  //   type: "collapse",
  //   name: "Admin Management",
  //   key: "admin",
  //   icon: <Icon fontSize="small" className="sidebar_icons">table_view</Icon>,
  //   route: "/admin-management",
  //   component: <AdminManagment />,
  // },
  // {
  //   type: "collapse",
  //   name: "Permission",
  //   key: "permission",
  //   icon: <Icon fontSize="small" className="sidebar_icons">Permission</Icon>,
  //   route: "/permissions",
  //   component: <Permissions />,
  // },
  // {
  //   type: "collapse",
  //   name: "Booking and Price",
  //   key: "billing",
  //   icon: <Icon fontSize="small" className="sidebar_icons">receipt_long</Icon>,
  //   route: "/billing",
  //   component: <Billing />,
  // },
  {
    type: "collapse",
    name: "Payment Details",
    key: "payment",
    icon: <Icon fontSize="small" className="sidebar_icons">payment</Icon>,
    route: "/payment",
    component: <PaymentDetails />,
  },
  {
    type: "collapse",
    name: "Profile",
    key: "profile",
    icon: <Icon fontSize="small" className="sidebar_icons">person</Icon>,
    route: "/profile",
    component: <Profile />,
  },
];

export default routes;
