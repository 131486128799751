import MDBox from "components/MDBox";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import MDTypography from "components/MDTypography";
import { useEffect, useState } from "react";
import { getProfile, fetchBookings } from "utils/api";
import RebookCards from "./components/rebook";
import BookingCard from "./components/booking";
import HotelBook from "./components/hotelbook";
import CarRental from "./components/carRental";
import { fetchSubstitueHotel } from "utils/api";
import { fetchFlightOffers } from "utils/api";
import { flightOffersById } from "utils/api";

function UserDashboard() {
  const [profile, setProfile] = useState("");
  const [active_tab, setActiveTab] = useState(1);
  const [flights, setFlights] = useState([]);
  const [loading, setLoading] = useState(false);
  const [hotels, setHotels] = useState([]);
  const [carData, setCarData] = useState([]);

  useEffect(() => {
    setLoading(true);
    allBookingData();
  }, []);

  const allBookingData = () => {
    setLoading(true);
    fetchBookings()
      .then(async (response) => {
        const flightsWithSubstitutes = [];
  
        if (response.data.flights.length > 0) {
          for (const item of response.data.flights) {
  
            try {
              const res = await fetchFlightOffers(item.id);
              
              const flightOffer = await flightOffersById(item.id)
              
              
              const substitutes = Array.isArray(res.data.paired_offers)
              ? res.data.paired_offers.map((offer) => ({
                outbound_offer: offer.outbound_offer,
                return_offer: offer.return_offer,
                total_price: offer.total_price,
              }))
              : [];
              
              const same_flight_sub = flightOffer?.data

              if(flightOffer.status == 200){
                const flightWithSubstitutes = {
                  ...item,
                  substitutes,
                  same_flight_sub,
                }    
                flightsWithSubstitutes.push(flightWithSubstitutes);
              }else{
                const flightWithSubstitutes = {
                  ...item,
                  substitutes,
                }
    
                flightsWithSubstitutes.push(flightWithSubstitutes);
              }
  
             
            } catch (error) {
              console.error(`Error fetching flight offers for ${item.id}:`, error);
              flightsWithSubstitutes.push({
                ...item,
                substitutes: [], 
                total_price : null,
              });
            }
          }
  
          setFlights(flightsWithSubstitutes);
          setLoading(false);
        }else{
          setLoading(false);
        }
       
        if (response.data.hotels.length > 0) {
          const hotelsWithSubstitutes = [];
  
          for (const item of response.data.hotels) {
            try {
              const res = await fetchSubstitueHotel(item.id);
              const hotelWithSubstitutes = {
                ...item,
                substitutes: Array.isArray(res.data) ? res.data : []
              };
              hotelsWithSubstitutes.push(hotelWithSubstitutes);
            } catch (error) {
              console.error(`Error fetching hotel substitutes for ${item.id}:`, error);
              hotelsWithSubstitutes.push({
                ...item,
                substitutes: []
              });
            }
          }
  
          setHotels(hotelsWithSubstitutes);
        }else{
          setLoading(false);
        }  
       
        if (response?.data?.cars) {
          setCarData(response.data.cars);
          setLoading(false);
        }else{
          setLoading(false);
        }
      })
      .catch((error) => {
        setLoading(false);
      });
  };

  useEffect(() => {
    setProfile(JSON.parse(localStorage.getItem("active-user")));
  }, []);

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox py={3}>
        <MDTypography className="dashboard_title">
          Welcome{" "}
          <strong style={{textTransform:"capitalize"}}>{`${profile?.first_name ?? ""} ${
            profile?.last_name ?? ""
          }`}</strong>
        </MDTypography>
        <RebookCards active={active_tab} handleChange={setActiveTab} />
        {active_tab === 1 && (
          <BookingCard
            flights={flights}
            loading={loading}
            allBookingData={allBookingData}
          />
        )}
        {active_tab === 2 && (
          <HotelBook
            hotels={hotels}
            loading={loading}
            allBookingData={allBookingData}
          />
        )}
        {active_tab === 3 && (
          <CarRental
            carData={carData}
            loading={loading}
            allBookingData={allBookingData}
          />
        )}
      </MDBox>
    </DashboardLayout>
  );
}

export default UserDashboard;
