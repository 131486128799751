import React from 'react';
import '../Front-Page/FrontPageHome.css';
import main from 'assets/img/main.png';
import BasicLayout from "layouts/authentication/components/BasicLayout";
import Navbar from './Navbar';
import AboutUs from './Aboutus';
import Services from './Services';
import Features from './Features';
import FAQ from './FAQ';
import Contactus from './Contactus';
import NewsletterFooter from './NewsletterFooter';
import pigi from 'assets/img/pigi.png';

const FrontPageHome = () => {
  return (
    <>
    <BasicLayout>
      <div className="home-container">
      
        <Navbar />
        <div className="hersecon">
          <div className="container text-center">
            <div className='mainpigisec'>
              <div className='pigisec'>
                <div>
                  <img src={pigi} alt="main" style={{ paddingLeft: '10px' }} />
                </div>
                <div className="mainsec">
                  <p className="mb-0">Save on Travel with Rebooker</p>
                </div>
              </div>
            </div>

            <h1>Effortless Price <br /> Tracking & <span className="highlight">Rebooking</span></h1>

         
            <div className='imagsectn'>
            <p className="subtitle">Take control of your travel reservations and never <br /> miss a price drop again.</p>
              <img src={main} alt="Left Image" className="" />
              <div className="reviwsection">
                <div className="reviwsection1">
                  <div>
                    <p className='ptag'><span className="gradient-text">16k+</span> Active Users</p>
                  </div>
                  <div>
                    <p className='ptag'><span className="gradient-text">8k+</span> Rebookings</p>
                  </div>
                  <div>
                    <p className='ptag'><span className="gradient-text">12k+</span> Reviews</p>
                  </div>
                </div>
              </div>
            </div>
         
            <AboutUs />
            <Services />
            <Features />
            <FAQ />
            <Contactus />
            <NewsletterFooter />
          </div>
        </div>
      </div>
    </BasicLayout>
    </>
  );
};

export default FrontPageHome;
