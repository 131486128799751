import { useState } from "react";

import { Link, useNavigate } from "react-router-dom";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import MDTypography from "components/MDTypography";
import bgImage from "assets/images/logo.png";
import MDButton from "components/MDButton";
import { IconButton } from "@mui/material";
import MDAlert from "components/MDAlert";
import BasicLayout from "layouts/authentication/components/BasicLayout";
import * as React from "react";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";
// Images
import Logo from "assets/images/auth/logo.png";
import { registerUser } from "utils/api";
import validator from "utils/validate";
import { verifyEmail } from "utils/api";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  boxShadow: 24,
  p: 4,
};

function Register() {
  const navigate = useNavigate();
  const [showAlert, setShowAlert] = useState(false);
  const [errorAlert, setErrorAlert] = useState(false);
  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const [formdata, setFormdata] = useState({
    first_name: null,
    last_name: null,
    email: null,
    password: null,
    confirm_password: null,
    user_type: "2",
  });
  const [errors, setErrors] = useState({
    first_name: false,
    last_name: false,
    email: false,
    password: false,
    confirm_password: false,
  });
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirm, setShowConfirm] = useState(false);
  const [otp, setOtp] = useState(Array(6).fill("")); // Assuming a 6-digit OTP
  const [email, setEmail] = useState(""); // Example email

  const handleToggle = (field) => {
    if (field == "password") setShowPassword(!showPassword);
    else setShowConfirm(!showConfirm);
  };

  const handleFormdata = (e) => {
    const { name, value } = e.target;
    setFormdata({ ...formdata, [name]: value });
    let error = validator(
      name,
      value,
      name === "confirm_password" && formdata.password
    );
    setErrors((prevErrors) => ({
      ...prevErrors,
      [name]: error,
    }));
  };

  const handleSignIn = () => {
    let empty = false;
    for (let i = 0; i < Object.keys(formdata).length; i++) {
      let error = validator(
        Object.keys(formdata)[i],
        formdata[Object.keys(formdata)[i]],
        Object.keys(formdata)[i] === "confirm_password" && formdata.password
      );
      if (error) {
        empty = true;
      }
      setErrors((prev) => ({
        ...prev,
        [Object.keys(formdata)[i]]: error,
      }));
    }
    if (!empty) {
      registerUser(formdata)
        .then((res) => {
          if (res.status === 200 || res.status === 201) {
            setEmail(formdata.email);
            setShowAlert("Please check your email for verification");
            setOpen(true);
            setTimeout(() => {
              setShowAlert(false);
            }, 3000);
          } else {
            setErrorAlert(res.data.email[0]);
            setTimeout(() => {
              setErrorAlert(false);
            }, 3000);
          }
        })
        .catch((error) => {
          console.log(error);
        });
    }
  };

  const handleChange = (e, index) => {
    const { value } = e.target;
    const newOtp = [...otp];

    // Allow only numeric input
    if (/^\d*$/.test(value) && value.length <= 1) {
      newOtp[index] = value;
      setOtp(newOtp);

      // Move to the next input automatically
      if (value && index < otp.length - 1) {
        const nextInput = document.querySelector(
          `input[name="otp-${index + 1}"]`
        );
        if (nextInput) {
          nextInput.focus();
        }
      }
    }
  };

  const handleKeyDown = (e, index) => {
    if (e.key === "Backspace" && otp[index] === "") {
      // Clear the current input and focus the previous one
      if (index > 0) {
        const prevInput = document.querySelector(
          `input[name="otp-${index - 1}"]`
        );
        if (prevInput) {
          prevInput.focus();
        }
      }
    }
  };

  const handleSubmit = () => {
    const otpCode = otp.join("");
    if (otpCode.length == 6) {
      verifyEmail(otpCode).then((res) => {
        if (res.status == 200) {
          if (!res?.data?.is_subscription && res?.data?.user_type === 2) {
            navigate("/subscription")
          } else {
            setTimeout(() => {
              navigate("/login")
            }, 1000)
          }
          localStorage.setItem("user_access_token", res.data.access);
          
          setOpen(false)
          setShowAlert(res.data.message)
          
        } else {
          setErrorAlert(res.data.error)
          setTimeout(() => {
            setErrorAlert(false)
          }, 2000)
        }
      }).catch((error) => {
        console.log("error", error);
      })
    }
  };

  const alertContent = () => (
    <MDTypography variant="body2" color="white">
      <MDTypography
        component="a"
        href="#"
        variant="body2"
        fontWeight="medium"
        color="white"
      >
        {showAlert}
        {errorAlert}
      </MDTypography>
    </MDTypography>
  );

  return (
    <BasicLayout>
      <div style={{ position: "absolute", right: "25px", top: "20px" }}>
        {showAlert && (
          <MDAlert color="info" dismissible>
            {alertContent("info")}
          </MDAlert>
        )}
        {errorAlert && (
          <MDAlert color="error" dismissible>
            {alertContent("error")}
          </MDAlert>
        )}
      </div>
      <div className="main_file">
        <div className="bg_login1">
          <div className="bg_image_login">
            <img src={Logo} />
          </div>
        </div>
        <div className="login_form login_form_inner">
          <div>
            <MDTypography
              variant="h3"
              fontWeight="medium"
              color="#3D4B7C"
              mt={1}
            >
              Let’s Sign Up 👇
            </MDTypography>
            <MDTypography display="block" variant="button" color="blue" my={1}>
              Hey, Enter your details to get sign up to your account.
            </MDTypography>
            <div>
              <input
                type="text"
                placeholder="First Name"
                name="first_name"
                value={formdata.first_name}
                onChange={handleFormdata}
                className="input_design"
              />

              {errors.first_name && (
                <>
                  <br />
                  <span style={{ color: "red", fontSize: "14px" }}>
                    {errors.first_name}
                  </span>
                </>
              )}
              <br />
              <input
                type="text"
                placeholder="Last Name"
                name="last_name"
                value={formdata.last_name}
                onChange={handleFormdata}
                className="input_design"
              />

              {errors.last_name && (
                <>
                  <br />
                  <span style={{ color: "red", fontSize: "14px" }}>
                    {errors.last_name}
                  </span>
                </>
              )}
              <br />
              <input
                type="email"
                placeholder="Email"
                name="email"
                value={formdata.email}
                onChange={handleFormdata}
                className="input_design"
              />

              {errors.email && (
                <>
                  <br />
                  <span style={{ color: "red", fontSize: "14px" }}>
                    {errors.email}
                  </span>
                </>
              )}
              <br />
              <input
                type={showPassword ? "text" : "password"}
                placeholder="Password"
                name="password"
                value={formdata.password}
                onChange={handleFormdata}
                className="input_design"
              />
              <IconButton
                size="small"
                disableRipple
                onClick={() => handleToggle("password")}
                style={{ marginLeft: "-40px" }}
              >
                {!showPassword ? <VisibilityOff /> : <Visibility />}
              </IconButton>
              {errors.password && (
                <>
                  <br />
                  <span style={{ color: "red", fontSize: "14px" }}>
                    {errors.password}
                  </span>
                </>
              )}
              <input
                type={showConfirm ? "text" : "password"}
                placeholder="Password Confirmation"
                name="confirm_password"
                value={formdata.confirm_password}
                onChange={handleFormdata}
                className="input_design"
              />
              <IconButton
                size="small"
                disableRipple
                onClick={() => handleToggle("confirm")}
                style={{ marginLeft: "-40px" }}
              >
                {!showConfirm ? <VisibilityOff /> : <Visibility />}
              </IconButton>
              {errors.confirm_password && (
                <>
                  <br />
                  <span style={{ color: "red", fontSize: "14px" }}>
                    {errors.confirm_password}
                  </span>
                </>
              )}
            </div>
            <div className="sign_butn">
              <MDButton
                variant="gradient"
                onClick={() => handleSignIn()}
                color="info"
                fullWidth
              >
                Register
              </MDButton>
            </div>
            <div>
              <MDTypography
                display="block"
                variant="button"
                color="blue"
                my={1}
              >
                Already have an account? <Link to="/login">Login</Link>
              </MDTypography>
            </div>
          </div>
        </div>
      </div>

      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        slots={{ backdrop: "static" }}  //-- to handle the outside modal click
      >
        <Box sx={style}>
          <div className="verify_main">
            <div className="verify_card">
              <img src={bgImage} alt="logo" />
              <MDTypography
                variant="h3"
                fontWeight="medium"
                color="#3D4B7C"
                mt={3}
              >
                Verify Your Email
              </MDTypography>

              <MDTypography
                display="block"
                variant="button"
                color="blue"
                my={1}
              >
                Verification code has been sent on{" "}
                <span style={{ color: "#2196F3", fontWeight: "400" }}>
                  {formdata?.email}
                </span>
              </MDTypography>

              <div
                className="otp_input_container"
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  marginTop: "20px",
                }}
              >
                {otp.map((digit, index) => (
                  <input
                    key={index}
                    type="text"
                    name={`otp-${index}`}
                    value={digit}
                    onChange={(e) => handleChange(e, index)}
                    onKeyDown={(e) => handleKeyDown(e, index)} // Handle backspace
                    onFocus={(e) => e.target.select()}
                    maxLength="1"
                    style={{
                      width: "40px",
                      height: "40px",
                      textAlign: "center",
                      fontSize: "20px",
                      background: "#e1e1e1",
                      border: "none",
                      borderRadius: "10px",
                      color: "#3D4B7C",
                      fontWeight: "600",
                    }}
                  />
                ))}
              </div>
              <div style={{ marginTop: "30px" }}>
                <MDButton
                  variant="gradient"
                  color="info"
                  fullWidth
                  onClick={handleSubmit}
                >
                  Confirm Code
                </MDButton>
              </div>
            </div>
          </div>
        </Box>
      </Modal>
    </BasicLayout>
  );
}

export default Register;
