import { Card } from "@mui/material";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import React, { useEffect, useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import ArrowBackIosNewIcon from "@mui/icons-material/ArrowBackIosNew";
import Loader from "components/Loader";
import { useTheme } from "@mui/material/styles";
import OutlinedInput from "@mui/material/OutlinedInput";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import Pagination from "@mui/material/Pagination";
import { encodeIdWithType } from "utils/encoder";
import { pastFlightsDetails } from "utils/api";
import { fetchFlightOffers } from "utils/api";
import { fetchSubstitueHotel } from "utils/api";
import PastFlightBooking from "./components/pastFlight";
import PastHotelBooking from "./components/pastHotel";
import PastCarBooking from "./components/pastCar";
import { flightOffersById } from "utils/api";

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

const names = ["Flight Booking", "Hotel Booking", "Car Booking"];
const booking_name = ["Booking accepted", "Booking eliminated", "Booking rejected"];

function getStyles(name, personName, theme) {
  return {
    fontWeight: personName.includes(name)
      ? theme.typography.fontWeightMedium
      : theme.typography.fontWeightRegular,
  };
}

const PassedBooking = () => {
  const navigate = useNavigate();
  const { id } = useParams();
  const [flights, setFlights] = useState([]);
  const [loading, setLoading] = useState(false);
  const [hotels, setHotels] = useState([]);
  const [carData, setCarData] = useState([]);
  const theme = useTheme();
  const [personName, setPersonName] = useState("Flight Booking");
  const [booking_type, setBookingType] = useState("Booking accepted");
  const [pageData, setPageData] = useState([]);
  const [filterData, setFilterData] = useState([])
  const [show, setShow] = useState(false);
  const [status, setStatus] = React.useState(null);

  const itemsPerPage = 5;
  const [page, setPage] = useState(1);

  const pageCount = Math.ceil(pageData?.length / itemsPerPage);

  const displayedItems = pageData?.slice(
    (page - 1) * itemsPerPage,
    page * itemsPerPage
  );

  console.log("pageDatapageDatapageData", pageData)

  const allBookingData = () => {
    setLoading(true);
    pastFlightsDetails()
      .then(async (response) => {
        const flightsWithSubstitutes = [];
        if (response.data.flights.length > 0) {
          for (const item of response.data.flights) {

            try {
              const res = await fetchFlightOffers(item.id);

              const flightOffer = await flightOffersById(item.id)

              const substitutes = Array.isArray(res.data.paired_offers)
                ? res.data.paired_offers.map((offer) => ({
                  outbound_offer: offer.outbound_offer,
                  return_offer: offer.return_offer,
                  total_price: offer.total_price,
                }))
                : [];

              const same_flight_sub = flightOffer?.data

              if (flightOffer.status == 200) {
                const flightWithSubstitutes = {
                  ...item,
                  substitutes,
                  same_flight_sub,
                }
                flightsWithSubstitutes.push(flightWithSubstitutes);
              } else {
                const flightWithSubstitutes = {
                  ...item,
                  substitutes,
                }

                flightsWithSubstitutes.push(flightWithSubstitutes);
              }
            } catch (error) {
              console.error(`Error fetching flight offers for ${item.id}:`, error);
              flightsWithSubstitutes.push({
                ...item,
                substitutes: [],
                total_price: null,
              });
            }
          }

          setFlights(flightsWithSubstitutes);
          setLoading(false);
          setPageData(flightsWithSubstitutes)
          setFilterData(flightsWithSubstitutes)
        }

        if (response.data.hotels.length > 0) {
          let data = [];
          for (let i = 0; i < response.data.hotels.length; i++) {
            let item = response.data.hotels[i];

            let res = await fetchSubstitueHotel(item.id);
            if (res.data) {
              item.substitutes = Array.isArray(res.data) ? res.data : [];
            }
            data.push(item);
          }
          setHotels(data);
          setFilterData(data)
        }

        if (response?.data?.cars) {
          setCarData(response.data.cars);
          setLoading(false);
        }
      })
      .catch((error) => {
        console.error("BookingCard: Error fetching data:", error);
        setLoading(false);
      });
  };

  useEffect(() => {
    allBookingData();
  }, []);

  const handlePageChange = (event, value) => {
    setPage(value);
  };

  useEffect(() => { });

  const handleBackButtonClick = () => {
    navigate(-1);
  };

  const handleChange = (event) => {
    setPage(1);
    const {
      target: { value },
    } = event;
    setPersonName(value);
    if (value == "Car Booking") {
      setPageData(carData);
    } else if (value == "Hotel Booking") {
      setPageData(hotels);
    } else {
      setPageData(flights);
    }
  };

  const handleBookingChange = (event) => {
    const {
      target: { value },
    } = event;

    setPage(1);
    setBookingType(value);

    const statusMapping = {
      "Booking accepted": "ACCEPT",
      "Booking eliminated": "ELIMINATE",
      "Booking rejected": "REJECT",
    };

    const selectedStatus = statusMapping[value];
    console.log("Selected status:", selectedStatus);

    const updatedPageData = filterData?.map((item) => ({
      ...item,
      substitutes: item.substitutes.filter(
        (substitute) => substitute.outbound_offer.status === selectedStatus
      ),
    }));

    setPageData(updatedPageData);
  };



  const redirection = (id) => {
    let final_id = encodeIdWithType(id, "flights");
    navigate(`/price-monitor/${final_id}`);
  };

  const getTime = (departure, arrival) => {
    const formatDateTime = (dateTimeStr) => {
      const [date, time, modifier] = dateTimeStr?.split(" ");
      let [hours, minutes] = time?.split(":");
      if (modifier === "PM" && hours !== "12") {
        hours = parseInt(hours, 10) + 12;
      } else if (modifier === "AM" && hours === "12") {
        hours = "00";
      }
      return new Date(`${date}T${hours}:${minutes}:00`);
    };
    const departureDate = formatDateTime(departure);
    const arrivalDate = formatDateTime(arrival);
    const differenceInMilliseconds = arrivalDate - departureDate;
    const hours = Math.floor(differenceInMilliseconds / (1000 * 60 * 60));
    const minutes = Math.floor(
      (differenceInMilliseconds % (1000 * 60 * 60)) / (1000 * 60)
    );

    return `${hours}hr ${minutes}min`;
  };

  return (
    <DashboardLayout>
      <DashboardNavbar />
      {loading ? (
        <Loader />
      ) : (
        <MDBox my={2}>
          <div className="user_name_icons">
            <div className="user_detail_section">
              <ArrowBackIosNewIcon onClick={handleBackButtonClick} />
              <MDTypography varient="h1" className="user_fullname">
                Booking History
              </MDTypography>
            </div>
          </div>

          <Card className="mt-4">
            <MDBox py={2} px={2}>
              <div className="filters_sections">
                <MDTypography variant="h6"></MDTypography>
                <div>
                  <div>
                    {/* <MDInput placeholder="Search" /> */}
                    <FormControl sx={{ width: 200, mx: 1 }}>
                      <InputLabel id="demo-multiple-name-label">
                        Booking
                      </InputLabel>
                      <Select
                        labelId="demo-multiple-name-label"
                        id="demo-multiple-name"
                        value={booking_type}
                        onChange={handleBookingChange}
                        input={<OutlinedInput label="Name" />}
                        MenuProps={MenuProps}
                      >
                        {booking_name?.map((name) => (
                          <MenuItem
                            key={name}
                            value={name}
                            style={getStyles(name, booking_type, theme)}
                          >
                            {name}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                    <FormControl sx={{ width: 200, mx: 1 }}>
                      <InputLabel id="demo-multiple-name-label">
                        Category
                      </InputLabel>
                      <Select
                        labelId="demo-multiple-name-label"
                        id="demo-multiple-name"
                        value={personName}
                        onChange={handleChange}
                        input={<OutlinedInput label="Name" />}
                        MenuProps={MenuProps}
                      >
                        {names?.map((name) => (
                          <MenuItem
                            key={name}
                            value={name}
                            style={getStyles(name, personName, theme)}
                          >
                            {name}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  </div>
                </div>
              </div>

              {personName == "Flight Booking" && (
                <PastFlightBooking
                  flights={displayedItems}
                  loading={loading}
                  allBookingData={allBookingData}
                />
              )}

              {personName == "Hotel Booking" && (
                <>
                  <PastHotelBooking
                    hotels={displayedItems}
                    loading={loading}
                    allBookingData={allBookingData}
                  />
                </>
              )}

              {personName == "Car Booking" && (
                <>
                  <PastCarBooking carData={displayedItems} loading={loading} />
                </>
              )}

              {
                displayedItems?.length > 0 &&
                <Pagination
                  count={pageCount}
                  page={page}
                  onChange={handlePageChange}
                  variant="outlined"
                  shape="rounded"
                />
              }
            </MDBox>
          </Card>
        </MDBox>
      )}
    </DashboardLayout>
  );
};

export default PassedBooking;
